
#profile {
    min-height: 60vh;
    position: relative;
}

.invalid-user {
    color: #929292;
    font-size: 3rem;
    text-align: center;
    margin: 16% auto;
}

.info_container {
    width: 100%;
}

.info_container p {
    margin-bottom: 10px;
}

.info_container p span {
    font-weight: bold;
    width: 94px;
    display: inline-block;
}

#pills-tabContent {
    padding: 0px 18px;
}

.profile_ajax_tabs .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #8b34e9 !important;
}

.no-shared-image {
    width: 100%;
    text-align: center;
    color: #8c8c8c;
}


.name_details .guarded {
    margin: 0 10px 0 4px;
    text-shadow: 1px 1px 1px rgb(141,59,255);
    font-size: 1.5rem;
    cursor: pointer;
}

.name_details .unguarded {
    color: #ab2d2e;
    margin: 0 10px 0 4px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
    font-size: 1.5rem;
    cursor: pointer;
}

.right_bookmark {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.right_bookmark > small {
    color: #722ed1;
    font-weight: 600;
}

.right_bookmark .fa-thumbs-up {
    color: #8b34e9;
    margin: 0 10px 0 4px;
    position: relative;
    top: -2px;
    cursor: pointer;
}

#profile .profile_right .nav-pills li a {
    min-width: fit-content;
    width: 154px;
    font-size: 0.96rem;
    text-align: center;
    background-color: #f7efff;
    margin: 2px;
}

#pills-avail ul li {
    list-style: circle;
    margin-left: 20px;
    background-color: #f9f4ffa1;
    margin-bottom: 10px;
    padding: 4px;
}
