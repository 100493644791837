#profile .profile_left .profile_images img {
    max-width: 200px;
    width: 100%;
    border-radius: 100%;
    margin: 0 auto;
    display: block;
}

#profile .profile_left .bio {
    margin-top: 25px;
}

#profile .profile_left .bio p {
    margin-bottom: 15px;
    text-align: justify;
}

#profile .profile_left .profile_title {
    position: relative;
}

#profile .profile_left .profile_title:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #ddd;
    top: 36%;
    z-index: -1;
}

#profile .profile_left .profile_title h6 {
    color: #ddd;
    margin-bottom: 25px;
    background-color: #fff;
    display: inline-block;
    padding: 0px 15px 0 0;
}

.profile_right {
    width: 100%;
}

#profile .profile_right .user_info {
    margin-bottom: 25px;
}

#profile .profile_right .user_info h3 span {
    color: #ddd;
    font-size: 12px;
    font-weight: normal;
}

#profile .profile_right .user_info h6 {
    font-weight: normal;
    margin: 0;
}

#profile .profile_right .user_info .right_bookmark h5 {
    color: #ddd;
    font-weight: normal;
    font-size: 18px;
}

#profile .profile_right .profile_match {
    margin-bottom: 35px;
}

#profile .profile_right .profile_match p {
    font-weight: bold;
}

#profile .profile_right .profile_match .bar_pipe {
    background-color: #ddd;
    height: 10px;
    width: 153px;
    position: relative;
}

#profile .profile_right .profile_match .bar_pipe:after {
    position: absolute;
    content: '';
    background-color: #722ed1;
    top: 0;
    width: 100%;
    max-width: 140px;
    height: 10px;
}

#profile .profile_right .after_head {
    margin-bottom: 75px;
}

#profile .profile_right .profile_ajax_tabs {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 25px;
}

#profile .profile_right .profile_ajax_tabs ul li {
    display: inline-block;
}

#profile .profile_right .profile_ajax_tabs ul li a {
    margin-right: 15px;
    color: #000;
}

#profile .profile_right .profile_ajax_tabs ul li a:hover {
    color: #722ed1;
}

#profile .profile_right .profile_ajax_tabs ul li.active a {
    color: #722ed1;
}

#profile .profile_right .single_listing {
    margin-bottom: 35px;
}

#profile .profile_right .single_listing h5 {
    margin-bottom: 25px;
}

#profile .profile_right .single_listing ul li {
    margin-bottom: 15px;
}

#profile .profile_right .details_listing .show_full {
    display: none;
}

#profile .profile_right .details_listing:hover .show_full {
    display: inline-block;
    cursor: pointer;
}

#profile .profile_right .details_listing .lisint span {
    font-weight: bold;
}

#profile .profile_right .details_listing .lisint p {
    margin-bottom: 10px;
}

.settings-btn .form-check {
    padding-left: 30px;
}

.profile-action {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: #b681ff;
    font-size: 0.9rem;
}

.profile-action > small {
    cursor: pointer;
}

.profile-action > small > b {
  color: #ff7bb6;
}

.profile-action > small > i {
  margin-left: 5px;
    cursor: pointer;
}


.sidebar-notification {
    background-color: #f9f8fb;
    box-shadow: -2px 2px 25px -8px rgba(0,0,0,0.2);
    overflow-y: auto;
}

.sidebar-notification::-webkit-scrollbar {
    display: none;
}

.sidebar-notification h4 {
    color: #b1b1b1;
    font-size: 1rem;
    margin: 10px 10px 14px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.notification-item {
    display: flex;
    flex-direction: row;
    margin: 10px;
    box-shadow: 0px 1px 6px -2px rgba(0,0,0,0.2);
    padding: 4px;
    background-color: rgb(249 240 247);
    align-items: center;
}

.notification-item .profile_img {
    margin-right: 10px;
}

.notification-item .profile_img img {
    width: 55px;
}

.notification-item .details .small_font {
    color: #8c8989;
}

.notification-item .details .small_font b {
    color: #8e8e8e;
}

.notification-item .details a {
    padding: 2px 0px;
}

.no-view {
    text-align: center;
    color: #8c8989;
}

.action-link > a {
    font-size: 0.9rem;
}

.schedule-dates {
    list-style: decimal;
    margin: 0 0 5px 15px;
    font-size: 0.8rem;
    color: #bbbbbb;
}

.notify-settings > p {
    line-height: 1;
    margin: 10px 10px 0px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .profile_match .bar_pipe {
        width: 100% !important;
    }

    .profile_match .bar_pipe:after {
        max-width: 95% !important;
    }
}

/*#profile_listing .profile_listing_left {*/
/*  background-color: #ddd;*/
/*  border-radius: 15px;*/
/*  margin-bottom: 30px;*/
/*}*/
/*#profile_listing .user_info_sum {*/
/*  padding: 35px 15px;*/
/*}*/
/*#profile_listing .user_info_sum .profile_img {*/
/*  margin-right: 10px;*/
/*}*/
/*#profile_listing .user_info_sum .profile_img img {*/
/*  max-width: 100px;*/
/*  width: 100%;*/
/*  box-shadow: 0px 5px 10px #00000017;*/
/*  border: 1px solid #812EED;*/
/*  border-radius: 100%;*/
/*}*/
/*#profile_listing .user_info_sum .details .chatnow {*/
/*  background-color: #722ed1;*/
/*  font-size: 12px;*/
/*  border-radius: 35px;*/
/*  padding: 0px 15px;*/
/*  color: #fff;*/
/*  margin-top: 10px;*/
/*  display: inline-block;*/
/*}*/
/*#profile_listing .user_info_sum .details .chatnow.pink_color {*/
/*  background-color: #ff7bb6;*/
/*}*/
/*#profile_listing .info_area .now_tabs ul {*/
/*  border: 0;*/
/*}*/
/*#profile_listing .info_area .now_tabs ul li a {*/
/*  color: #2d2a2a;*/
/*  background-color: #abaaaa;*/
/*  border-color: #abaaaa;*/
/*  margin-right: 10px;*/
/*}*/
/*#profile_listing .info_area .now_tabs ul li a.active {*/
/*  color: #fff;*/
/*  background-color: #722ed1;*/
/*  border-color: #722ed1;*/
/*}*/
/*#profile_listing .info_area .now_tabs .view_profile {*/
/*  color: #ed1c24;*/
/*  font-weight: bold;*/
/*  margin-top: 10px;*/
/*  margin-right: 10px;*/
/*}*/
/*#profile_listing .info_area .now_tabs .view_profile:hover {*/
/*  color: #722ed1;*/
/*}*/
/*#profile_listing .profile_listing_right .ads_img img {*/
/*  margin-bottom: 25px;*/
/*  width: 100%;*/
/*}*/
/*#profile_listing .header_filter {*/
/*  border: 1px solid #ddd;*/
/*  padding: 15px;*/
/*  border-radius: 15px;*/
/*  margin-bottom: 30px;*/
/*}*/
/*#profile_listing .header_filter .now_search {*/
/*  background-color: #722ed1;*/
/*  color: white;*/
/*  border: 0;*/
/*  padding: 6px 35px;*/
/*  border-radius: 35px;*/
/*  margin-top: 12px;*/
/*  cursor: pointer;*/
/*}*/

@media (min-width: 768px) {
    .info_area {
        background-color: #f8f8f8;
        border-radius: 0 15px 15px 0;
        height: 100%;
        padding: 15px;
    }
}

@media (max-width: 767px) {
    #profile_listing .profile_listing_left {
        border-radius: 0;
    }

    #profile_listing .info_area {
        border-radius: 0;
        max-height: 600px;
        height: auto;
    }

    #profile_listing .info_area .details-text {
        height: auto;
    }

    #profile_listing .info_area .now_tabs ul li a {
        margin-right: 0;
        padding: .2rem .5rem;
        margin-right: 5px;
    }

    #profile_listing .info_area .now_tabs .view_profile {
        font-size: 14px;
        margin-top: 0;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1364px) {
    #profile_listing .user_info_sum .details .chatnow {
        padding: 0px 7px;
    }
}

#contact_us .contect_left {
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 0 10px 4px #00000012;
    border-radius: 7px;
    max-width: 600px;
    margin: 0 auto;
}

#contact_us .contact_cap .form-check {
    margin-bottom: 7px;
}

#contact_us .contact_cap .cap_img img {
    height: 150px;
    width: 100%;
}

.category_listing {
    margin: 50px 0 50px 0;
    border: 1px solid #70707021;
    border-radius: 9px;
    padding: 16px;
}

.category_listing .category_btn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 9px;
    padding: 12px 15px;
    margin-right: 20px;
    border: 1px solid #70707021;
    width: 100%;
    display: inline-block;
    text-align: center;
    color: #2d2a2a;
    font-size: 16px;
}

.category_listing .category_btn:hover {
    background: #722ed1;
    box-shadow: 0px 10px 20px #14141429;
    color: white;
    border: none;
}

.category_listing .category_btn.active {
    background: #722ed1;
    box-shadow: 0px 10px 20px #14141429;
    color: white;
    border: none;
}

.category_listing .filter_items {
    position: relative;
}

.category_listing .owl-nav .owl-prev.disabled,
.category_listing .owl-nav .owl-next.disabled {
    display: none;
}

.category_listing .owl-nav {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
}

.category_listing .owl-nav button {
    outline: none;
}

.category_listing .owl-nav .owl-prev {
    display: none;
}

.category_listing .owl-nav .fa {
    font-size: 35px;
}

.category_listing .owl-nav .owl-next {
    position: absolute;
    right: -17px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s;
}

.category_listing:hover .owl-nav {
    opacity: 1;
    visibility: visible;
}

#product_listing .single_product {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 9px;
    margin-bottom: 30px;
    transition: all 0.5s;
}

#product_listing .single_product:hover {
    box-shadow: 0 0 11px 4px #722ed129;
}

#product_listing .single_product img {
    width: 100%;
}

#product_listing .single_product h6 {
    margin-bottom: 0;
}

#product_listing .single_product h6 a {
    color: #722ed1;
}

#product_listing .single_product p {
    color: #ff7bb6;
    font-size: 14px;
}

.load_more_pagination {
    margin-top: 35px;
}

@media (max-width: 767px) {
    #product_listing {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .category_listing .owl-carousel {
        z-index: 0 !important;
    }
}

#my_account .my_profile_waper {
    background: #fff;
    padding: 50px 30px;
    border-radius: 7px;
}

#my_account .my_ac_left {
    border-right: 1px dashed #ddd;
    padding-right: 30px;
    height: 100%;
}

#my_account .my_ac_left .avatar {
    position: relative;
}

#my_account .my_ac_left .avatar #update_avatar {
    position: absolute;
    right: 0;
    top: 0;
    color: #722ed1;
}

#my_account .my_ac_left .avatar #update_avatar:hover {
    color: #ff7bb6;
}

#my_account .my_ac_left .avatar img {
    max-width: 100px;
    width: 100%;
    margin: 0 auto;
    display: block;
}

#my_account .my_ac_left .left_profile_navigation {
    margin-top: 30px;
}

#my_account .my_ac_left .nav-pills .nav-link.active, #my_account .my_ac_left .nav-pills .show > .nav-link {
    background-color: #722ed1;
}

#my_account .profile_right p {
    margin-bottom: 15px;
}

#my_account .profile_right .profile_setting .info_container {
    margin-bottom: 15px;
    position: relative;
}

#my_account .profile_right .profile_setting p {
    margin-bottom: 5px;
}

#my_account .profile_right .profile_setting p span {
    font-weight: bold;
    width: 94px;
    display: inline-block;
}

#my_account .profile_right .profile_setting small {
    font-size: 12px;
    display: block;
    margin-top: -5px;
    font-style: italic;
    color: #ff7bb6;
}

#my_account .profile_right .update_profile {
    position: absolute;
    right: 0;
    top: -10px;
}

#my_account .profile_right .update_profile a {
    display: inline-block;
    padding: 3px 14px;
    border-radius: 50px;
    color: #722ed1;
    font-size: 14px;
    line-height: 24px;
}

#my_account .profile_right .add_gallery {
    color: #fff;
    padding: 6px 35px;
    display: inline-block;
    width: 150px;
    text-align: center;
    border-radius: 50px;
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.5s ease;
    background-color: #722ed1;
}

#my_account .my_conntection .user_info_sum {
    margin-top: 25px;
    background-color: #f8f8f8;
    padding: 15px;
    border-radius: 7px;
}

#my_account .my_conntection .user_info_sum:hover {
    box-shadow: 0 0 5px 0px #c1c1c1;
}

#my_account .my_conntection .user_info_sum img {
    max-width: 100px;
    margin-right: 15px;
}

#my_account .my_conntection .user_info_sum .details p {
    margin-bottom: 0;
}

.account .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.settings-btn {
    width: 100%;
}

.settings-btn > button {
    margin: 10px 4px;
}

.settings-btn button.btn.btn-light.btn_outline {
    height: 28vh;
    border-radius: 6px;
    border: none;
    background-color: #d9cbec !important;
}

.settings-btn button.btn.btn-light.btn_outline:last-child {
    background-color: #f1ddf0 !important;
}

.settings-btn button.btn.btn-light.btn_outline > i {
    color: #8d3bff;
    margin-left: 8px;
}

.settings-btn button.btn.btn-light.btn_outline:last-child > i {
    color: #fd7ab7;
    margin-left: 8px;
}

#delete-account-modal .modal_header {
    background-color: #f74e4e;
}

#delete-account-modal .modal_header h4 {
    color: #ffffff;
    border: none;
}

#delete-account-modal .modal_body .btn-danger {
    background-color: #f74e4e;
}


.modal_waper_main_profile {
    padding: 20px;
}

/*====================
    Avatar Screen
=====================*/
.sd_avater {
    max-height: 350px;
    overflow-y: auto;
}

.sd_avater::-webkit-scrollbar {
    display: none;
}

.avatar_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
}

.avatar_container .single_avatar {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 9px;
}

.avatar_container .single_avatar img {
    max-width: 70px;
    width: 100px;
    transition: all 0.9s;
    cursor: pointer;
}

.avatar_container .single_avatar img:hover {
    transform: scale(1.1);
}

.selected-img {
    margin-bottom: 10px;
    border: 2px solid rgba(0, 0, 0, 0.3);
}

.left_profile_navigation > * {
    margin-bottom: 10px;
}

#my_account .btn_outline:not(.btn_purple) {
    width: 100%;
    background-color: #e2e2e2;
    position: relative;
}

.btn_purple.btn-light:hover {
    color: #212529;
    background-color: #995af1;
    border-color: #722ed1;
}

.empty-list {
    margin-top: 22%;
    text-align: center;
    width: 100%;
}

.empty-list a {
    width: 200px;
}

.empty-list h3 {
    color: #6f6f6f;
    font-size: 1.5rem;
}

.btn.btn-light.btn_outline.btn_purple {
    color: #e8e8e8;
}

.interest_btn {
    color: #585858;
    font-weight: 500;
}

.action-link > * {
    margin-right: 17px;
    font-weight: 600;
}

.reason-block {
    display: flex;
    flex-direction: column;
}

#block_confirm.modal_tnc .tnc_content {
    background: #f74e4e;
}

#block_confirm.modal_tnc .tnc_content .top_carret i {
    color: #f74e4e;
}

#block-reason {
    width: 100%;
    border: 1px solid rgb(216 218 222);
}

.reason-block label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reason-block label input {
    margin-right: 10px;
}

.connection-list {
    display: flex;
    flex-direction: row;
}

.connection-list > .nav-tabs {
    width: 100%;
}

.connection-list ~ .tab-content > .active {
    display: flex;
    flex-direction: row;
}

.connection-list > ul li a {
    margin: 0px 10px 0 0;
    padding: 6px;
    border-radius: 4px 4px 0px 0px;
    color: #6e7479;
    position: relative;
    cursor: pointer;
}

.connection-list .nav-tabs .nav-item.show .nav-link,
.connection-list .nav-tabs .nav-link.active {
    color: #722ed1;
}

.connection-list > ul li a:hover,
.connection-list > ul li a.active {
    background-color: #ffe6fa;
}

.connection-list > .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #dd66c5;
}

.gallery_waper .gal_image .remove-img {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffa998;
    border: none;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    padding: 0px 0 0 1px;
    outline: none;
}

.gallery_waper .gal_image .remove-img:hover {
    background-color: #fa78b9;
}

.my-profile .log-out {
    margin-left: 8px;
    padding: 8px;
}

.my-profile .modal_header h4 {
    padding-bottom: 5px;
    border-bottom: 1px solid;
    margin-bottom: 20px;
}

.my-profile .rn {
    position: absolute;
    right: 12px;
    top: -10px;
    background-color: #e5a8ff;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.4);
    color: #fff;
}

.my-profile .empty-fr {
    color: #cecece;
    text-align: center;
    padding: 40px 0 20px;
    font-size: 1.4rem;
}

.btn_light_blue {
    background-color: #b3b9bd;
}

.page-message {
    width: 73%;
    float: right;
    text-align: left;
    font-size: 0.9rem;
    color: #7c7d7d;
}

small.page-message {
    font-size: 0.8rem;
    color: #ff7bbf;
    font-style: italic;
}

.my_profile_waper .close-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    right: 18px;
    top: 53px;
    position: absolute;
    background-color: #bf98f7;
    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    z-index: -99;
}

.my-profile .page_title h3 {
    width: 73%;
    float: right;
    text-align: left;
}

.my-profile .page_title h3:after {;
    left: 4%;
}

.pin {
    color: #878888;
    margin-right: 10px;
}

.pin ~ i {
    color: #722ed1;
}


#my_account .btn_outline {
    width: 100%;
}

#my_account .btn_purple_mid_light {
    position: relative;
}

.empty-gallery:hover {
    box-shadow: 0px 0px 6px 0px rgb(182 129 255);
}

.my_conntection .empty-list .btn_outline {
    max-width: 300px;
}

.cst-label {
    color: #8c8c8c;
    font-weight: 600;
    margin: 4px 0 8px;
}

#guard-modal .form-control {
    line-height: 1.2;
}

#guard-modal button {
    margin: 15px 0;
}

.user_name .guarded {
    margin-left: 10px;
    text-shadow: 1px 1px 1px rgb(141, 59, 255);
    cursor: pointer;
}

.user_name .unguarded {
    color: #ab2d2e;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    margin-left: 10px;
}

#guard-info p, #guard-info p b {
    margin-bottom: 10px;
    color: #828282;
}

@media (min-width: 576px) {
    #my_account .container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 991px) {

    #my_account .container {
        width: 100%;
    }

    .my-profile .page_title h3 {
        width: 100%;
        float: none;
        text-align: center;
    }

    .my-profile .page_title h3:after {;
        left: 50%;
    }

    .profile_right {
        width: 100%;
    }

    #my_account .my_ac_left .left_profile_navigation {
        align-items: center;
    }

    #my_account .my_profile_waper {
        position: relative;
        padding: 25px;
    }

    #my_account .my_profile_waper > div {
        position: relative;
        justify-content: center;
    }

    .profile-tab {
        position: fixed;
        display: none;
        flex-direction: column;
        background-color: #faf6fb;
        width: 100%;
        max-width: 100% !important;
        height: 100vh;
        top: 0px;
        left: 0px;
        overflow-y: auto;
        overscroll-behavior-y: contain;
        border-radius: 0px;
        box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.4);
        z-index: 9;
        padding: 0px 15px 40px;
    }

    #my_account .my_profile_waper .close-btn {
        display: flex;
        position: absolute;
        z-index: 99;
    }

    .page-message {
        width: 100%;
        float: none;
        text-align: center;
    }

    #my_account .my_ac_left {
        border: none;
        background: #f8f8f8;
        padding: 25px 25px 25px 25px;
        border-radius: 10px;
    }

    .title_and_close {
        display: flex !important;
        border-bottom: 1px dashed #ddd;
        color: #6b6b6b;
    }

    .title_and_close h6 {
        color: #6b6b6b;
    }

    .my_profile_waper .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
        min-width: 300px;
        padding: 0px;
    }

    .overlay_body {
        background: #000000ad;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        display: none;
    }

    #my_account .btn_outline {
        width: 100%;
        max-width: 350px;
    }

}


@media screen and (max-width: 767px) {
    #info_helper {
        font-size: 16px;
        color: #ff7bb6;
        cursor: pointer;
    }
}


@media (min-width: 768px) {
    #my_account .container {
        max-width: 1140px;
    }
}


@media (max-width: 1024px) {

    .settings-btn button.btn.btn-light.btn_outline {
        width: 100%;
        max-width: 96% !important;
        height: 19vh;
    }

}

@media (max-width: 991px) {

    .settings-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .settings-btn button.btn.btn-light.btn_outline {
        width: 100%;
        max-width: 96% !important;
        height: 39vh;
    }

}
