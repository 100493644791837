

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lh-14 {
  line-height: 1.4 !important;
}

body {
  font-size: 16px !important;
  line-height: 28px;
  font-family: sans-serif;
  overflow-x: hidden;
}
body.home {
  background-color: #f1e6ff;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body p {
  text-align: justify !important;
}

::placeholder {
  color: #cecbcb !important;
}

section {
  padding: 30px 0 0 0;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a, button {
  font-size: 16px;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
}
a:hover, button:hover {
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Fira Sans", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #2d2a2a;
}

h1 {
  font-size: 65px;
}

h2 {
  font-size: 45px;
}

h3 {
  font-size: 35px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

p {
  color: #2d2a2a;
  font-size: 16px;
  line-height: 28px;
}

ul {
  list-style: none;
  font-size: 16px;
}

ol {
  font-size: 16px;
}

.btn.focus, .btn:focus {
  box-shadow: none;
}

.small_font {
  font-size: 12px;
}

.purple_color {
  color: #722ed1;
}

.red_color {
  color: #ed1c24;
}

.pink_color {
  color: #ff7bb6;
}

.page_title {
  position: relative;
  margin-bottom: 60px;
}
.page_title h3 {
  position: relative;
  padding-bottom: 10px;
}
.page_title h3:after {
  content: '';
  position: absolute;
  height: 2px;
  background: #722ed1;
  width: 100%;
  max-width: 60px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

header#main_header {
  position: absolute;
  width: 100%;
  z-index: 1;
}
header .navbar-expand-lg .navbar-nav .nav-link {
  color: #fff;
  font-size: 15px;
}
header .navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #ff7bb6;
}
header .login_special {
  position: relative;
}
header .login_special:before {
  position: absolute;
  content: '';
  background: #934cee;
  width: 100%;
  height: 85px;
  top: -21px;
  border-radius: 0 0 50px 50px;
  z-index: -1;
  box-shadow: 0px 2px 5px -3px rgba(255,255,255,0.6);
}
header .login_special:hover:before {
  background: #ff7bb6;
}
header .login_special:hover .nav-link {
  color: #000 !important;
}
header .login_area {
  background-color: #fff;
  padding: 15px;
  position: absolute;
  top: 55px;
  left: -60%;
  width: 200px;
  text-align: center;
  box-shadow: -1px 3px 17px 5px #a7a4a44f;
  border-radius: 7px;
  display: none;
}
header .login_area .now_search {
  background-color: #722ed1;
  color: white;
  border: 0;
  padding: 6px 35px;
  border-radius: 35px;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
header .login_area .icon i {
  font-size: 44px;
  margin-top: -45px;
  display: block;
  color: #fff;
}
header .login_area_mobile {
  /*padding: 15px;*/
  /*display: none;*/
  /*z-index: 3;*/
  /*background: #6e23d6;*/
  /*position: absolute;*/
  /*box-shadow: 0px 3px 6px -3px rgba(0,0,0,0.5);*/
  /*width: 200px;*/
  /*left: -40px;*/
  /*top: 37px;*/
}
header .login_area_mobile {
  padding: 15px;
  display: none;
  z-index: 110;
  background: #924bee;
  position: fixed;
  box-shadow: 0px 3px 6px -3px rgba(0,0,0,0.5);
  width: 100vw;
  left: 0;
  top: 0;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login_area_mobile form {
  width: 85%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
}

header .login_area_mobile .now_search {
  background-color: #722ed1;
  color: white;
  border: 0;
  padding: 6px 35px;
  border-radius: 35px;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}

.login_area_mobile form > div:nth-of-type(2) {
  margin-bottom: 30px;
}

header .login_area_mobile button:active {
  outline: none !important;
}

header .login_area_mobile button {
  margin: 6px 0 !important;
}

.forget-pass a {
  font-size: 0.8rem;
  color: #ac9ac3;
  font-weight: 600;
}

#chatbody .chat_sidebar_waper {
  background-color: #F6F6F6;
  border-radius: 22px 0px 0px 22px;
  padding: 0 0 0 10px;
  height: calc(100vh - 133px);
  overflow-y: auto;
  overflow-x: hidden;
}
#chatbody .chat_sidebar_waper::-webkit-scrollbar {
  display: none;
}
#chatbody .user_own {
  padding: 25px 25px 0 15px;
}
#chatbody .profile_img {
  margin-right: 15px;
  position: relative;
}
#chatbody .profile_img img {
  box-shadow: 0px 5px 10px #00000017;
  border: 1px solid #812EED;
  border-radius: 100%;
}
#chatbody .profile_img .top_ticker {
  position: absolute;
  top: -9px;
  right: 0;
  background-color: #fff;
  line-height: 1;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid #722ed1;
  font-size: 9px;
}
#chatbody .search_waper {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0 25px 0 15px;
}
#chatbody .search_waper input {
  border-radius: 34px;
  border: 0;
}
#chatbody .search_waper ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
  color: #e2e2e2;
}
#chatbody .friends_profile {
  padding: 25px 25px 25px 15px;
  cursor: pointer;
}
#chatbody .friends_profile.active {
  background: #fff;
  border-radius: 60px 0px 0px 60px;
}
#chatbody .chatnow {
  background: #722ed1;
  font-size: 10px;
  color: white;
  padding: 5px 10px;
  border-radius: 18px;
}
#chatbody .chat_body .chat_head {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
#chatbody .chat_body .chat_head .left a {
  color: #ddd;
  margin-right: 10px;
  font-size: 18px;
}
#chatbody .chat_body .chat_head .right h5 {
  margin: 0;
}
#chatbody .chat_body .chat_messages {
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
}
#chatbody .chat_body .chat_messages::-webkit-scrollbar {
  display: none;
}

#chatbody .chat_body .chat_messages .message .msg_waper {
  max-width: 500px;
  width: 100%;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border-radius: 35px;
  margin-bottom: 25px;
  display: inline-block;
}
#chatbody .chat_body .chat_messages .message p {
  padding: 10px 20px;
}
#chatbody .chat_body .chat_messages .message .sender_avt {
  position: absolute;
  bottom: 0;
  left: 0;
}
#chatbody .chat_body .chat_messages .message .sender_avt img {
  max-width: 15px;
  height: 15px;
  border: 1px solid #812eed;
  border-radius: 100%;
}
#chatbody .chat_body .chat_messages .message.self {
  text-align: right;
}
#chatbody .chat_body .chat_messages .message.self .sender_avt {
  left: initial;
  right: 0;
}
#chatbody .chat_body .chat_date {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  color: #ddd;
}
#chatbody .chat_footer {
  background: #f8f8f8;
  padding: 25px;
  border-radius: 80px;
}
#chatbody .chat_footer #send_msg {
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 34px;
  border: 0;
  padding: 0 70px 0 10px;
}
#chatbody .chat_footer ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
  color: #e2e2e2;
}
#chatbody .chat_footer .gray_color {
  color: #adacac;
  font-size: 18px;
}
#chatbody .chat_footer .gray_color:hover {
  color: #585858;
}
#chatbody .chat_footer .smily {
  margin-left: 10px;
}
#chatbody .chat_footer .smily i {
  color: #adacac;
  font-size: 18px;
}
#chatbody .chat_footer .smily i:hover {
  color: #585858;
}
#chatbody .chat_footer .send_action {
  position: absolute;
  right: -5px;
  top: -5px;
}
#chatbody .chat_footer .send_action i {
  background-color: #722ed1;
  padding: 15px;
  color: #fff;
  border-radius: 100%;
  font-size: 20px;
  transition: all 0.5s;
  cursor: pointer;
}
#chatbody .chat_footer .send_action i:hover {
  background-color: #6f35bb;
}
#chatbody .ads img {
  width: 100%;
  margin-bottom: 25px;
}

.hero_buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.hero_buttons > a {
  max-width: 310px;
}

.notification-icons {
  display: flex;
  position: absolute;
  justify-content: space-evenly;
  align-items: center;
  width: 85px;
  top: 30px;
  right: 0;
}

.mobile-notification-icons {
  position: fixed;
  right: 0;
  top: 110px;
}

.notification-uc, .notification-fr, .notification-gn {
  position: relative;
}

.notification-uc .notify {
  position: absolute;
  top: 10px;
  right: 0px;
}

.notification-fr .fr-num, .notification-gn .gn-num {
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 0.8rem;
  color: #674a90;
}

.notification-icons i {
  color: #5108b7;
  font-size: 1.1rem;
  cursor: pointer;
}

.notification-fr i {
  color: #ffffff;
}

.mobile-notification-icons .notification-uc,
.mobile-notification-icons .notification-fr ,
.mobile-notification-icons .notification-gn {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
  width: 36px;
  border-radius: 8px 0px 0px 6px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px;
}

.mobile-notification-icons .notification-uc i {
  color: #5108b7;
  font-size: 1.1rem;
  font-weight: 700;
}

.mobile-notification-icons .notification-fr i {
  color: #5108b7;
  font-size: 1.1rem;
  font-weight: 700;
}

.mobile-notification-icons .notification-uc .notify,
.mobile-notification-icons .notification-fr .notify ,
.mobile-notification-icons .notification-gn .notify {
  position: absolute;
  top: 13px;
  right: 13px;
}

.mobile-fixed {
  display: none;
  right: 0;
  top: 178px;
  width: 35px;
  height: 76px;
  padding: 2px;
  position: fixed;
  margin-bottom: 3px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 8px 0px 0px 6px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
}

.mobile-fixed a {
  padding: 0;
}

.mobile-fixed i {
  color: #5108b7;
  font-size: 1.1rem;
  font-weight: 700;
}

.mobile-fixed .story-menu {
  display: inline-flex;
  flex-direction: row;
  transform: rotate(90deg);
  height: 30px;
  width: 70px;
  justify-content: space-evenly;
  align-items: center;
}

.mobile-fixed span {
  font-size: 0.8rem;
  color: #722ed1;
  font-weight: 600;
  margin-right: 4px;
}

#fr .modal_body .fr {
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.4);
  padding: 6px;
}


#fr .modal_body .fr .profile_img {
  margin-right: 50px;
}

#fr .modal_body .fr .profile_img img {
  width: 50px;
}

#fr .fr .user_info_sum .details a {
  margin-right: 10px;
}

#fr .fr-btn {
  position: relative;
}


#fr .empty-fr {
  color: #cecece;
  text-align: center;
  padding: 40px 0 20px;
  font-size: 1.4rem;
}

#fr .fr .details {
  display: flex;
  flex-direction: column;
}

#fr .fr .action-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.modal-open {
  overflow: hidden !important;
  padding-right: 0px !important;
}


#modal-rec-audio #play-rec-audio {
  width: 100%;
  height: 30px;
}

#modal-rec-audio .modal-body {
  color: #6b6f73;
}

#modal-rec-audio .modal-body h4 {
  font-size: 1.3rem;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

#modal-rec-audio .rec-control {
  margin: 11px;
  display: flex;
  flex-direction: row;
}

.rec-control > i {
  margin-right: 10%;
  display: block;
  padding: 4px 8px;
  box-shadow: 0px 0px 5px -3.4px;
  cursor: pointer;
}

.rec-control > i.pressed {
  box-shadow: 0px 0px 5px 3px rgb(168,179,228) !important;
}

.equalizer {
  height: 20px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  align-items: baseline;
  position: relative;
}

.equalizer div {
  width: 3px;
  height: 100%;
  background-color: #9c5df5;
  margin: 0px 1px;
}

.equalizer div.up-down {
  animation: move-up-and-down .3s infinite alternate;
  transform-origin: bottom left;
}

@keyframes move-up-and-down {
  0% {
    opacity: .3;
    transform: scaleY(.05);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}


.spinner-border.spinner-color {
  width: 4rem;
  height: 4rem;
  z-index: 9999;
  margin: 16% 50%;
  position: absolute;
  color: rgba(222,102,255,0.7);
}

.cur-pointer {
  cursor: pointer;
}

.custom-file-label {
  overflow: hidden;
}

.info-fixed {
  width: 100%;
  height: 15px;
  overflow: hidden;
  position: relative;
}

.info-fixed > div {
  display: flex;
  flex-direction: row;
  width: fit-content;
  position: absolute;
  right: -380px;
  animation: right-to-left 25s linear 2s infinite;
}

.info-fixed > div > p {
  padding: 0 10px;
  line-height: 1;
  color: #cbc8ce;
}

.info-fixed > div > p:not(:last-of-type) {
  border-right: 1px solid rgba(0,0,0,0.3);
}

@keyframes right-to-left {
  from {right: -380px;}
  to {right: 105%;}
}

.general-notifications {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.notify-settings {
  border-bottom: 1px solid rgba(0,0,0,0.2);
  border-top: 1px solid rgba(0,0,0,0.2);
  padding: 10px 0;
}

.notify-settings > p {
  line-height: 1;
  margin: 10px 10px 0px !important;
  font-weight: 600;
  color: #6e6d6f;
}

.ck-box-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ck-box {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ck-box > p {
  font-size: 0.8rem;
  color: #504f52;
  margin-bottom: 0 !important;
}

.toggle-switch {
  width: 70px;
  position: relative;
}

.toggle-switch > div > input {
  opacity: 0;
}

.toggle-switch > div > label {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  border-radius: 4px;
  border: 1px groove rgba(0,0,0,0.2);
}

.toggle-switch > div > label > div {
  height: 100%;
  width: 185px;
  display: flex;
  flex-direction: row;
  position: relative;
  left: -56px;
  transition: left linear 300ms;
}

.input-toggle:checked + label > div {
  left: -8px;
}

.toggle-switch > div > label > div > div {
  width: 20px;
  background-color: #fefdff;
}

.toggle-switch > div > label > div > p {
  width: 56px;
  display: flex;
  background-color: #722ed1;
  font-size: 0.9rem;
  margin: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 0px 10px -6px rgba(0,0,0,0.4);
  color: white;
  text-shadow: 0px 0px 1px rgba(0,0,0,0.72);
}

.toggle-switch > div > label > div > p:last-of-type {
  background-color: #d7d7d8;
}

.inline-download-app {
  display: none;
  margin-left: 10px;
  color: #ffe9f3;
  font-size: 1.1rem;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

.inline-download-app i {
  margin-right: 6px;
}

.download-app {
  position: fixed;
  top: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 11px;
  width: fit-content;
  height: fit-content;
}

.download-app > a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #c39bf7;
  padding: 30px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.7);
}

.download-app p {
  font-size: 1rem;
  margin-right: 0px;
  position: relative;
  transform: rotate(90deg);
  margin-bottom: 30px;
  text-shadow: 2px -1px 4px rgba(0,0,0,0.6);
  color: #ffffff;
}

.download-app i {
  font-size: 1.6rem;
  color: #ffffff;
  text-shadow: 2px 2px 1px rgba(0,0,0,0.2);
}

.download-app:hover i {
  text-shadow: 2px 2px 1px rgba(0,0,0,0.2);
}

#app_body .download-app {
  display: none;
}

.tab-pane {
  min-height: 30vh;
}

.mobile-login {
  position: absolute;
  right: 11vw;
  width: fit-content;
  padding: 0;
  top: 20px;
}

.mobile-login > a {
  padding: 0;
  font-size: 1.1rem;
  color: #eadefb;
}

@media (max-width: 768px) {

  .home .modal_tnc .tnc_content .top_carret {
    right: 45% !important;
  }

  #chatbody .pos_initial_mobile {
    position: initial !important;
  }

  #chatbody .chat_area_show_hide {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    padding: 15px;
    z-index: 9;
    width: 100%;
  }

  #chatbody .chat_body .chat_messages {
    height: calc(100vh - 195px);
  }

  .chat_area_show_hide {
    display: none;
  }


}
#hero_area {
  background: #AF7BF3;
  display: block;
  position: relative;
  height: 100vh;
  max-height: 1100px;
  padding-top: 200px;
}
#hero_area:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  bottom: -1px;
  background: url(/images/new_shape.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  z-index: 0;
}

/*#hero_area:after {*/
/*  position: absolute;*/
/*  content: '';*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  left: 0;*/
/*  bottom: -99%;*/
/*  background: url("../images/header_bg.png");*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  z-index: -1;*/
/*}*/
#hero_area .hero_wraper .hero_text {
  transform: translateY(50px);
}
#hero_area .hero_wraper .hero_text h1 {
  font-size: 42px;
  margin: 0;
}
#hero_area .hero_wraper .hero_text h4 {
  margin: 15px 0 0 0;
  line-height: 28px;
}
#hero_area .hero_wraper .hero_text h5 {
  margin-top: 20px;
  line-height: 28px;
}
#hero_area .hero_wraper .hero_text p {
  margin: 15px 0 40px 0;
}
#hero_area .hero_wraper .right_img_hero img {
  max-width: 440px;
  width: 100%;
}

#scroll_next {
  position: relative;
}

.icon-scroll {
  position: absolute;
  left: 50%;
  width: 39px;
  height: 63px;
  margin-left: -20px;
  bottom: 60px;
  box-shadow: inset 0 0 0 1px #722ed1;
  border-radius: 25px;
  z-index: 1;
}
.icon-scroll:before {
  position: absolute;
  left: 50%;
  content: '';
  width: 12px;
  height: 12px;
  background: #722ed1;
  margin-left: -6px;
  top: 8px;
  border-radius: 15px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(46px);
    transform: translateY(46px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(46px);
    transform: translateY(46px);
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1364px) {
  .empty_space {
    height: 200px;
  }
}
@media (max-width: 768px) {
  #hero_area {
    padding-top: 80px;
  }

  #hero_area .hero_wraper .hero_text {
    transform: translateY(73px);
  }
  #hero_area .hero_wraper .hero_text h1 {
    margin: 0;
  }

  #hero_area .hero_wraper .hero_text h4 {
    margin: 0;
  }

  #hero_area .hero_wraper .right_img_hero img {
    transform: translateY(100px);
  }
}
@media (max-width: 767px) {
  .empty_space {
    height: 300px;
  }
}
.btn_now_purple {
  color: #fff;
  padding: 9px 17px;
  display: inline-block;
  width: 150px;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 3px 6px #00000029;
  transition: 0.5s ease;
}
.btn_now_purple:hover {
  color: #fff;
  box-shadow: none;
  transform: translateY(-2px);
}

.btn_pink {
  background: #ff7bb6;
}

.btn_purple {
  background: #722ed1;
}

.btn_white {
  background: white;
  color: #000;
}
.btn_white:hover {
  color: #000;
}

@media (max-width: 768px) {
  .home .btn_now_purple {
    display: block;
    width: 100%;
    margin-bottom: 14px;
  }
}
/*----- Interest Buttons ----*/
.interest_btn {
  color: #722ed1;
  display: inline-block;
  border: 1px solid #722ed1;
  padding: 0px 12px;
  border-radius: 50px;
  margin-right: 5px;
  font-size: 14px;
  transition: all 0.5s;
  cursor: pointer;
  margin-bottom: 15px;
}
.interest_btn:hover {
  background-color: #722ed1;
  color: white;
}

/*Out line button for profile*/
.danger_link {
  color: #f00;
  font-weight: bold;
  margin-left: 15px;
}

.btn_outline {
  color: #000;
  padding: 6px 12px;
  display: inline-block;
  width: 150px;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 3px 6px #00000029;
  transition: 0.5s ease;
  border: 1px solid #000;
}
.btn_outline:hover {
  color: #fff;
  box-shadow: none;
  background-color: #722ed1;
  border-color: transparent;
  transform: translateY(-2px);
}

/* Load more Button CSS */
.load_more {
  color: #fff;
  padding: 9px 17px;
  display: inline-block;
  width: 150px;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0px 3px 6px #00000029;
  transition: 0.5s ease;
  background-color: #722ed1;
}
.load_more:hover {
  color: #fff;
  box-shadow: none;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .interest_btn {
    text-align: center;
  }

  .btn_outline {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
}
.left_right_info {
  margin-bottom: 60px;
}
.left_right_info .left_right_img img {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  display: block;
}
.left_right_info .right_text_area h3, .left_right_info .right_text_area h2 {
  margin-bottom: 35px;
  font-size: 35px;
  margin-top: 0;
}
.left_right_info .right_text_area h5 {
  background-color: #fff;
  display: inline-block;
  padding: 3px 11px;
  border-radius: 50px;
}
.left_right_info .right_text_area p {
  text-align: justify;
}
.left_right_info .right_text_area p span {
  background: #722ed1;
  width: 24px;
  height: 24px;
  color: #fff;
  display: inline-block;
  line-height: 1.6;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .left_right_info {
    margin-bottom: 20px;
  }
}
#mainfaq {
  margin: 50px 0;
}
#mainfaq #faq .card {
  margin-bottom: 30px;
  border: 0;
}
#mainfaq #faq .card .card-header {
  border: 0;
  border-radius: 2px;
  padding: 0;
  background: #fff;
}
#static #mainfaq #faq .card .card-header {
  padding: 8px;
  background: #f7eeff;
}

#mainfaq #faq .card .card-header .btn-header-link {
  display: block;
  text-align: left;
  color: #722ed1;
  padding: 20px;
  white-space: initial;
}
#mainfaq #faq .card .card-header .btn-header-link:after,
#mainfaq #faq .card .card-header h3.collapsed:after {
  content: '';
  background-image: url("/images/awesome-arrow-down.png");
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  background-repeat: no-repeat;
  right: 21px;
  max-width: 19px;
}
#mainfaq #faq .card .card-header .btn-header-link.collapsed {
  color: black;
}
#mainfaq #faq .card .card-header .btn-header-link.collapsed:after,
#mainfaq #faq .card .card-header h3:after {
  content: '';
  background-image: url("/images/awesome-arrow-up.png");
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  background-repeat: no-repeat;
  right: 21px;
  max-width: 19px;
}
#mainfaq #faq .card .collapsing {
  line-height: 30px;
}
#mainfaq #faq .card .collapse {
  border: 0;
}

#main_footer {
  position: relative;
  margin-top: 10px;
  padding: 0 0 60px;
  overflow-x: hidden;
}
#main_footer:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 25%, 100% 0%, 100% 100%, 0% 100%);
  background: #722ed1;
}
#main_footer .footer_menu {
  padding-top: 60px;
}
#main_footer .footer_menu ul li {
  display: inline-block;
  margin-right: 25px;
}
#main_footer .footer_menu ul li a {
  color: #fff;
}
#main_footer .footer_menu ul li a:hover {
  color: #000;
}
#main_footer .copy {
  margin-top: 25px;
}
#main_footer .copy p {
  color: #fff;
}

.newsletter_area {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 7px #00000036;
  text-align: center;
}
.newsletter_area .title {
  font-size: 25px;
}
.newsletter_area p {
  color: #565656;
}
.newsletter_area .form {
  margin-top: 20px;
}
.newsletter_area .form input {
  border: 0;
  border-bottom: 1px solid #ddd;
}
.newsletter_area .form input:focus {
  outline: none;
  box-shadow: none;
}
.newsletter_area .form button {
  width: 200px;
  background: #722ed1;
  border: 0px;
}

#scrollUp {
  position: fixed;
  right: 20px;
  bottom: 30px;
  width: 30px;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  border: 2px solid #8a19fa;
  color: #8a19fa;
  text-align: center;
  background-color: #ffffff;
  border-radius: 100px;
  z-index: 999;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  cursor: pointer;
}
#scrollUp:hover {
  background-color: #8a19fa;
  color: #ffffff;
}
#scrollUp.show {
  opacity: 1;
}
#scrollUp i {
  -webkit-animation: mymove 1s ease-in 0s infinite alternate;
  animation: mymove 1s ease-in 0s infinite alternate;
  display: inline-block;
  position: relative;
}

@-webkit-keyframes mymove {
  from {
    top: -5px;
  }

  to {
    top: 5px;
  }
}
@keyframes mymove {
  from {
    top: -5px;
  }

  to {
    top: 5px;
  }
}
.mobile_menu_waper {
  padding: 15px 0;
}
.mobile_menu_waper .logo_waper img {
  width: 100%;
  max-width: 182px;
}

.button_container {
  position: absolute;
  top: 20px;
  right: 14px;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 100;
  transition: opacity .25s ease;
}
.button_container.active {
  position: fixed;
}
.button_container:hover {
  opacity: .7;
}
.button_container span {
  background: white;
  border: none;
  height: 3px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 11px;
  width: 80%;
}
.button_container span:nth-of-type(3) {
  top: 22px;
  width: 60%;
}
.button_container.active .top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #fff;
}
.button_container.active .middle {
  opacity: 0;
  background: #fff;
}
.button_container.active .bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #fff;
}
.button_container.active span:nth-of-type(3) {
  width: 100%;
}

.overlay {
  position: fixed;
  background: #722ed1;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;
}
.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.overlay.open li {
  animation: fadeInRight .5s ease forwards;
  animation-delay: .35s;
}
.overlay.open li:nth-of-type(2) {
  animation-delay: .4s;
}
.overlay.open li:nth-of-type(3) {
  animation-delay: .45s;
}
.overlay.open li:nth-of-type(6) {
  animation-delay: .50s;
  z-index: 1;
}
.overlay nav {
  position: relative;
  height: 70%;
  top: 45%;
  transform: translateY(-50%);
  font-size: 50px;
  font-family: 'Vollkorn', serif;
  font-weight: 400;
  text-align: center;
}
.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.overlay ul li {
  display: block;
  height: 25%;
  height: calc(100% / 8);
  min-height: 50px;
  position: relative;
  opacity: 0;
}
.overlay ul li a {
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
}
.overlay ul li a:hover:after, .overlay ul li a:focus:after, .overlay ul li a:active:after {
  width: 100%;
}
.overlay ul li .drop_mobile {
  display: none;
}
.overlay ul li .drop_mobile li {
  height: initial !important;
  min-height: auto;
  margin: 10px 0;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}
.page_hero {
  height: 200px;
  clip-path: polygon(0 0, 100% 0%, 100% 60%, 0 100%);
  background: transparent linear-gradient(90deg, #812eed 0%, #ff7bb6 100%) 0% 0% no-repeat padding-box;
  position: relative;
}

.modal_waper_main .reg_left {
  padding-top: 30px;
}
.modal_waper_main .reg-right {
  padding: 30px 0;
  overflow: hidden;
  position: relative;
}
.modal_waper_main .reg-right:after {
  position: absolute;
  content: '';
  background: url(/images/shape.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  right: -300px;
  bottom: -500px;
}
.modal_waper_main .reg-right .cap_main {
  margin: 50px 0 30px 0;
  display: block;
  text-align: center;
}
.modal_waper_main .reg-right .cap_main img {
  max-width: 220px;
  width: 100%;
  margin-bottom: 20px;
}
.modal_waper_main .reg-right .cap_main .cap_inputs {
  text-align: left;
  max-width: 220px;
  margin: 0 auto;
}
.modal_waper_main .reg-right .cap_main .cap_inputs h6 {
  font-size: 14px;
}
.modal_waper_main .continue {
  margin-bottom: 25px;
}

.modal_close {
  position: absolute;
  top: -9px;
  right: -9px;
  z-index: 9;
  background: #722ed1;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
}
.modal_close i {
  color: #fff;
}

.bg_right_modal {
  background-color: #EBE1F9;
}

.reg_form {
  padding: 0 15px 0px 0px;
  margin-top: 25px;
}
.reg_form .cap_main_reg h6 {
  margin-bottom: 25px;
}
.reg_form .cap_main_reg .cap_img img {
  max-width: 150px;
  width: 100%;
  margin-right: 25px;
}
.reg_form .lets_get_started {
  margin-top: 25px;
}
.reg_form .lets_get_started .btn_now_purple {
  width: initial;
  border: 0;
  margin: 0 auto;
  display: block;
}

.modal_tnc .modal_header {
  padding: 60px 0;
  text-align: center;
}
.home .modal_tnc .modal_header {
  padding: 18px 0;
  text-align: center;
}
.modal_tnc .modal_header i {
  font-size: 50px;
  border: 2px solid #722ed1;
  border-radius: 50px;
  padding: 15px;
  width: 92px;
  height: 92px;
  color: #722ed1;
  margin-bottom: 30px;
}
.modal_tnc .modal_header p {
  color: #BBBBBB;
}
.modal_tnc .tnc_content {
  background: #722ed1;
  padding: 30px 50px;
  position: relative;
}
.modal_tnc .tnc_content .top_carret,
.home .modal_tnc .tnc_content .top_carret {
  position: absolute;
  top: -25px;
  right: 50%;
}
.modal_tnc .tnc_content .top_carret i,
.home .modal_tnc .tnc_content .top_carret i {
  font-size: 60px;
  color: #fff;
}
.modal_tnc .tnc_listing {
  margin: 40px 0;
}

.home .modal_tnc .tnc_listing {
  margin: 10px 0;
}

.modal_tnc .tnc_listing ul li {
  color: #fff;
  list-style: initial;
  margin-bottom: 15px;
}

.home .modal_tnc .tnc_listing ul li {
  margin-bottom: 8px;
}

.image_for_left img {
  width: 100%;
  margin-top: 50px;
}

.tree_img {
  position: absolute;
  right: -46px;
  top: 60%;
}

@media (max-width: 768px) {
  .continue a {
    max-width: 200px;
    margin: 0 auto;
  }

  .home .tnc_content .continue a {
    max-width: 100%;
    margin: 0 auto;
    color: #722ed1;
  }

  .reg_form {
    padding: 0 15px 0px 15px;
  }

  .cap_img {
    margin-bottom: 25px;
  }

  .tree_img {
    display: none;
  }
}
#profile .profile_left .profile_images img {
  max-width: 200px;
  width: 100%;
  border-radius: 100%;
  margin: 0 auto;
  display: block;
}
#profile .profile_left .bio {
  margin-top: 25px;
}
#profile .profile_left .bio p {
  margin-bottom: 15px;
  text-align: justify;
}
#profile .profile_left .profile_title {
  position: relative;
}
#profile .profile_left .profile_title:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 36%;
  z-index: -1;
}
#profile .profile_left .profile_title h6 {
  color: #ddd;
  margin-bottom: 25px;
  background-color: #fff;
  display: inline-block;
  padding: 0px 15px 0 0;
}
#profile .profile_right .user_info {
  margin-bottom: 25px;
}
#profile .profile_right .user_info h3 span {
  color: #ddd;
  font-size: 12px;
  font-weight: normal;
}
#profile .profile_right .user_info h6 {
  font-weight: normal;
  margin: 0;
}
#profile .profile_right .user_info .right_bookmark h5 {
  color: #ddd;
  font-weight: normal;
  font-size: 18px;
}
#profile .profile_right .profile_match {
  margin-bottom: 35px;
}
#profile .profile_right .profile_match p {
  font-weight: bold;
}
#profile .profile_right .profile_match .profile_bar_pipe {
  background-color: #ddd;
  height: 10px;
  width: 153px;
  position: relative;
}
#profile .profile_right .profile_match .profile_bar_pipe #variable {
  position: absolute;
  background-color: #722ed1;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 10px;
}

#profile .profile_right .after_head {
  margin-bottom: 75px;
}
#profile .profile_right .profile_ajax_tabs {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 25px;
}
#profile .profile_right .profile_ajax_tabs ul li {
  display: inline-block;
}
#profile .profile_right .profile_ajax_tabs ul li a {
  margin-right: 15px;
  color: #000;
}
#profile .profile_right .profile_ajax_tabs ul li a:hover {
  color: #722ed1;
}
#profile .profile_right .profile_ajax_tabs ul li.active a {
  color: #722ed1;
}
#profile .profile_right .single_listing {
  margin-bottom: 35px;
}
#profile .profile_right .single_listing h5 {
  margin-bottom: 25px;
}
#profile .profile_right .single_listing ul li {
  margin-bottom: 15px;
}
#profile .profile_right .single_listing ul li span {
  font-weight: bold;
}

@media (max-width: 767px) {
  .profile_match .bar_pipe {
    width: 100% !important;
  }
  .profile_match .bar_pipe:after {
    max-width: 95% !important;
  }
}
#profile_listing .profile_listing_left {
  background-color: #ddd;
  border-radius: 15px;
  margin-bottom: 30px;
  height: 215px;
}
#profile_listing .user_info_sum {
  padding: 35px 15px;
}
#profile_listing .user_info_sum .profile_img {
  margin-right: 20px;
}
#profile_listing .user_info_sum .profile_img img {
  max-width: 100px;
  width: 100%;
  box-shadow: 0px 5px 10px #00000017;
  border: 1px solid #812EED;
  border-radius: 100%;
}
#profile_listing .user_info_sum .details .chatnow {
  background-color: #722ed1;
  font-size: 12px;
  border-radius: 35px;
  padding: 0px 15px;
  color: #fff;
  margin-top: 10px;
  display: inline-block;
}
#profile_listing .user_info_sum .details .chatnow.pink_color {
  background-color: #ff7bb6;
}
#profile_listing .info_area .now_tabs ul {
  border: 0;
}
#profile_listing .info_area .now_tabs ul li a {
  color: #2d2a2a;
  background-color: #cacaca;
  border-color: #cacaca;
  margin-right: 10px;
}
#profile_listing .info_area .now_tabs ul li a.active {
  color: #fff;
  background-color: #722ed1;
  border-color: #722ed1;
}
#profile_listing .info_area .now_tabs .view_profile {
  color: #ed1c24;
  font-weight: bold;
  margin-top: 10px;
  margin-right: 10px;
}
#profile_listing .info_area .now_tabs .view_profile:hover {
  color: #722ed1;
}
#profile_listing .profile_listing_right .ads_img img {
  margin-bottom: 25px;
  width: 100%;
}
#profile_listing .header_filter {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 10px;
}
#profile_listing .header_filter .now_search {
  background-color: #722ed1;
  color: white;
  border: 0;
  padding: 6px 35px;
  border-radius: 35px;
  margin-top: 12px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .info_area {
    background-color: #f8f8f8;
    border-radius: 0 15px 15px 0;
    height: 100%;
    padding: 15px;
  }
}
@media (max-width: 767px) {
  #profile_listing .profile_listing_left {
    border-radius: 0;
    height: auto;
  }
  #profile_listing .info_area {
    border-radius: 0;
  }
  #profile_listing .info_area .now_tabs ul li a {
    margin-right: 0;
    padding: .2rem .5rem;
    margin-right: 5px;
  }
  #profile_listing .info_area .now_tabs .view_profile {
    font-size: 14px;
    margin-top: 0;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1364px) {
  #profile_listing .user_info_sum .details .chatnow {
    padding: 0px 7px;
  }
}
#contact_us .contect_left {
  /*text-align: center;*/
}
#contact_us .contect_left img {
  width: 100%;
  max-width: 500px;
}
#contact_us .contact_right .address_media {
  display: flex;
  align-items: center;
}
#contact_us .contact_right .address_media i {
  width: 42px;
  height: 42px;
  border: 1px solid #722ed1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  line-height: 1;
  color: #722ed1;
  transition: all 0.5s;
}
#contact_us .contact_right .address_media a {
  color: #2d2a2a;
}
#contact_us .contact_right .address_media p {
  line-height: 25px;
}
#contact_us .contact_right .address_media:hover i {
  background-color: #722ed1;
  color: white;
}
#contact_us .social_area {
  margin: 60px 0;
}
#contact_us .social_area .social_icons {
  margin-top: 40px;
  text-align: center;
}
#contact_us .social_area .social_icons a {
  background-color: #ff7bb6;
  box-shadow: 0px 10px 20px #ED1C2433;
  border-radius: 40px;
  width: 60px;
  height: 60px;
  display: inline-flex;
  font-size: 30px;
  color: #fff;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 20px;
  transition: all 0.5s;
}
#contact_us .social_area .social_icons a:hover {
  background-color: #722ed1;
}

.category_listing {
  margin: 50px 0 50px 0;
  border: 1px solid #70707021;
  border-radius: 9px;
  padding: 16px;
}
.category_listing .category_btn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 9px;
  padding: 12px 15px;
  margin-right: 20px;
  border: 1px solid #70707021;
  width: 100%;
  display: inline-block;
  text-align: center;
  color: #2d2a2a;
  font-size: 16px;
}
.category_listing .category_btn:hover {
  background: #722ed1;
  box-shadow: 0px 10px 20px #14141429;
  color: white;
  border: none;
}
.category_listing .category_btn.active {
  background: #722ed1;
  box-shadow: 0px 10px 20px #14141429;
  color: white;
  border: none;
  outline: none;
}
.category_listing .filter_items {
  position: relative;
}
.category_listing .owl-nav .owl-prev.disabled,
.category_listing .owl-nav .owl-next.disabled {
  display: none;
}
.category_listing .owl-nav {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.category_listing .owl-nav button {
  outline: none;
}
.category_listing .owl-nav .owl-prev {
  display: none;
}
.category_listing .owl-nav .fa {
  font-size: 35px;
}
.category_listing .owl-nav .owl-next {
  position: absolute;
  right: -17px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
}
.category_listing:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

#product_listing .single_product {
  border: 1px solid #ddd;
  padding: 10px 10px 13px;
  border-radius: 9px;
  margin-bottom: 30px;
  transition: all 0.5s;
}
#product_listing .single_product:hover {
  box-shadow: 0 0 11px 4px #722ed129;
}
#product_listing .single_product img {
  width: 100%;
  height: 160px;
  margin-bottom: 15px;
  object-fit: contain;
}
#product_listing .single_product h6 {
  margin-bottom: 0;
  height: 80px;
}
#product_listing .single_product h6 a {
  color: #722ed1;
}
#shop_help ~ #product_listing .single_product p {
  color: #ff7bb6;
  font-size: 1.6rem;
  text-align: center !important;
  margin-bottom: 6px;
}

.load_more_pagination {
  margin-top: 35px;
}


#id_show_notice {
  display: none;
  cursor: pointer;
}

@media (max-width: 767px) {
  #product_listing {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .category_listing .owl-carousel {
    z-index: 0 !important;
  }
  .profile_listing_right {
    display: none;
  }

  #scroll_next {
    display: none;
  }

  #hero_area .hero_wraper .btn_now_purple {
    display: initial;
  }

  #id_show_notice {
    display: flex;
    position: fixed;
    top: 75px;
    right: 0px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.4);
    width: 36px;
    border-radius: 8px 0px 0px 8px;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 3px;
    background-color: #fff;
    z-index: 999;
  }

  #closeable_notice {
    display: none;
  }

}

html {
  scroll-behavior: smooth;
}

#btn-display-none{
  display: none;
}


.top_colseable_area .now-alert {
  color: white;
  background-color: #722ed1;
  border-color: #722ed1;
}

.top_colseable_area .now-alert #remvoe_notice {
  font-size: 32px;
  color: #fff;
  opacity: 1;
  outline: none;
}

#id_show_notice i {
  font-size: 32px;
  color: #722ed1;
}

.btn_danger {
  background: #f74e4e;
  outline: none !important;
}

.btn_purple {
  outline: none !important;
}

.gallery_waper > .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
}

.gallery_waper .gal_image {
  border-radius: 7px;
  border: 1px solid #ddd;
  padding: 10px;
  position: relative;
  margin: 6px 6px 30px 6px;
  width: 178px;
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery_waper .gal_image img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.home .form-check,
#contact_us .form-check {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home .form-check-input,
#contact_us .form-check-input {
  margin-top: 0;
}

#contact_us .form-check-input {
  position: relative;
  top: -2px;
  margin-right: 6px;
}

.static #static  h2 {
  color: #6d6d6d;
  font-size: 1.8rem;
}

.static #static h2:not(:nth-of-type(1)) {
  margin-top: 44px;
}

.static #static p {
  color: #808885;
  font-weight: 400;
  margin-bottom: 12px;
}

.static #static h3:not(.purple_color) {
  color: #6d6d6d;
  font-size: 1.2rem;
  margin-top: 10px;
  cursor: pointer;
  padding: 4px 20px 4px 4px;
}

.static #static .card-header {
  box-shadow: 0px 0px 6px -2px rgba(0,0,0,0.4);
}

.static #static h4 {
  color: #6d6d6d;
  font-size: 1.3rem;
  margin-top: 22px;
}

.static #static ul {
  padding-left: 20px;
}

.static #static ul li {
  color: #808885;
  font-weight: 400;
  margin-bottom: 12px;
  list-style: disc;
}

.static #static .terms ul li {
  list-style: none;
}

.static #static .terms ul.use-list-style li {
  list-style: disc;
}

.now_submit {
  background-color: #722ed1;
  color: white;
  border: 0;
  padding: 6px 35px;
  border-radius: 35px;
  margin-top: 12px;
  cursor: pointer;
}

.disabled {
  color: #888888 !important;
}

#product_listing .single_product_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#product_listing .single_product_wrapper .single_product {
  max-width: 300px;
}

@media (max-width: 991px) {

  .inline-download-app {
    display: initial;
  }

  .download-app {
    display: none;
  }

  #app_body .inline-download-app {
    display: none;
  }

  .mobile-fixed {
    display: flex;
  }

  #fr .fr .action-btn {
    display: flex;
    flex-direction: column;
  }

  #fr .fr .action-btn a {
    margin: 2px 0px !important;
  }

  .notification-icons {
    display: none;
  }

  .newsletter_area .form {
    display: initial !important;
  }
  .newsletter_area .form button {
    width: 100%;
  }
  #product_listing .single_product h6 a {
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    width: 100%;
  }
  #my_account .my_profile_waper {
    padding: 50px 0px;
  }
  #my_account .my_ac_left {
    border-right: 0;
    padding-right: 0;
    height: 100%;
    border-bottom: 1px dashed #ddd;
    margin-bottom: 30px;
  }
  .profile_right {
    margin-top: 35px;
  }
  #my_account .page_title{
    margin-bottom: 0;
  }
  .my_ac_left .btn_outline{
    box-shadow: none;
    border: none;
  }
  .modal_waper_main_profile .btn_purple {
    color: #fff;
    width: 100%;
  }
  .filter-input {
    max-width: initial;
  }
  #profile_listing .header_filter .now_search {
    background-color: #722ed1;
    color: #fff;
    border: 0;
    padding: 6px 35px;
    border-radius: 35px;
    margin-top: 12px;
    cursor: pointer;
    width: 100%;
  }
  #profile_listing .info_area {
    padding: 0 15px 15px 15px;
  }
  #profile_listing .paginate {
    margin-bottom: 30px;
  }
  .single_product.sidebar_product h6 {
    padding: 0 10px;
  }

  .single_product.sidebar_product p {
    padding: 0 10px;
    margin-bottom: 15px;
  }
  .connection-list {
    /*border-bottom: 1px dashed #ddd;*/
    padding-bottom: 10px;
  }
  .empty-list h3, .empty-list h4 {
    color: #6f6f6f;
    font-size: 1.2rem;
    margin-bottom: 60px;
  }
  .empty-list {
    margin-top: 60px;
    text-align: center;
    width: 100%;
    display: block;
    color: #6f6f6f;
  }
  .empty-list a {
    width: 200px;
    margin: 0 auto;
  }
  .empty-chat-list {
    margin-top: 60px;
    text-align: center;
  }
  .empty-chat-list h3 {
    margin-bottom: 60px;
  }
  .empty-chat-list .btn_outline {
    margin: 0 auto;
  }
  .chat .container-fluid {
    padding-left: 15px;
  }
}

.cst-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(189,189,189,0.4);
  top: 0;
  left: 0;
  z-index: 9999;
}

.cst-modal .card {
  width: 90%;
  box-shadow: 0px 10px 100px -20px rgba(0,0,0,0.4);
}

.cst-modal .card .card-header {
  color: #ffffff;
  background-color: #9b57f9;
}

.cst-modal .card .card-header > p {
  color: #ffffff;
  line-height: 1.3;
  text-align: left !important;
}

.cst-modal .card .card-header > p:last-of-type {
  color: #d9d9d9;
  font-style: italic;
}

.cst-modal .card .card-body {
  max-height: 90vh;
  overflow-y: auto;
}

.cst-modal.info-add .card .card-body {
  justify-content: flex-start;
  text-align: start;
}

.cst-modal .card .card-body p {
  color: #a3a4a5;
  margin-bottom: 10px;
}

.cst-modal .card .card-body .card-text {
  color: #fe77b0;
}

.cst-modal .card .card-body > button {
  width: 130px;
}

.cst-modal .card .card-body label {
  color: #a3a4a5;
}

.cst-modal .card .card-body select {
  height: 34px;
  width: 150px;
  margin-left: 10px;
  color: #a3a4a5;
  padding: 5px;
}

.cst-modal .card .card-body .times {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 66vh;
  overflow-y: auto;
  margin-bottom: 20px;
}

.times .form-check {
  margin: 6px;
  width: 140px;
}

.cst-modal.info-add .card-body textarea {
  width: 100%;
  padding: 10px;
  resize: none;
  overflow-y: auto;
}

.info-action {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.info-action > div:last-of-type {
  min-width: 145px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


@media (max-width: 767px) {
  .page_hero {
    height: 100px;
  }

  #hero_area {
    padding-top: 50px;
  }

  #hero_area .hero_wraper .hero_text h1 {
    font-size: 32px;
  }

  #hero_area .hero_wraper .hero_text h4 {
    font-size: 22px;
  }

  #hero_area .hero_wraper .hero_text h5 {
    font-size: 16px;
  }

  #scroll_next {
    display: none;
  }

  .icon-scroll {
    bottom: 160px;
  }

}

@media (max-width: 414px) {
  .cst-modal .card {
    top: -3px;
    height: calc(100vh + 5px);
    width: 100vw;
  }

  .cst-modal.info-add .card-body {
    max-height: 76vh;
    overflow-y: auto;
  }

}

@media (max-width: 375px) {
  .cst-modal .card .card-body {
    text-align: center;
  }

  .cst-modal .card .card-body .times {
    justify-content: center;
  }

}

@media (max-width: 320px) {

  #hero_area .hero_wraper .hero_text h1 {
    font-size: 26px;
  }

  .cst-modal.info-add .card-body {
    max-height: 70vh;
  }

  .info-action > div:last-of-type {
    flex-grow: 1;
  }

}

section.forget-pass, section.page-not-found {
  min-height: 64vh;
}

.forget-pass .row, .page-not-found .row {
  align-items: center;
  justify-content: center;
}

.page-not-found h3 {
  font-size: 1.4rem;
  color: #a466ef;
}

.reset-choice {
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  padding: 30px;
  border-radius: 4px;
  width: 95%;
  max-width: 550px;
}

.reset-choice button {
  width: 100%;
  margin: 10px auto;
}

#reset-modal h4 {
  font-size: 1.2rem;
  color: #bd7ff9;
}

.btn_purple_light {
  background-color: #b681ff !important;
  color: #ffffff;
}

.btn_purple_mid_light {
  background-color: #8d3bff !important;
  color: #ffffff;
}

.nav-btn {
  border: none;
  width: auto;
  min-width: 100px;
  padding: 6px 0px;
  display: block;
  border-radius: 50px;
}
header .navbar-expand-lg .nav-link.nav-btn:hover {
  color: #ffffff;
}

@media (max-width: 414px) {
  .icon-scroll {
    bottom: 30px;
  }
}

.modal {
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.how-to-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: 100%;
  align-items: center;
}

.how-to-content > div {
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
  padding: 10px;
}

.how-to-content > .page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.how-to-content > .page a {
  width: 150px;
  margin: 2px 6px;
}

.how-to-content > div > h4 {
  color: #afabab;
  border-bottom: 1px dashed rgba(0,0,0,0.4);
}

#contact_us .cap_img {
  width: 200px;
}

@media (max-width: 767px) {

  .how-to-content {
    width: 100%;
    margin: 20px;
  }

  .how-to-content > div > div > iframe {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .how-to-content > div > div > iframe {
    height: 50vw;
  }
}

@media (max-width: 320px) {
  .home #hero_area {
    padding-top: 10px;
  }

  .home h2 {
    font-size: 1.4rem !important;
  }

  .home h3 {
    font-size: 1.2rem !important;
  }

  .home h4 {
    font-size: 1.1rem !important;
  }

  .home h5 {
    font-size: 0.9rem !important;
  }

  .home p {
    font-size: 0.8rem !important;
  }

  .home #hero_area .hero_text p {
    margin: 15px 0 5px 0;
  }

  .home #hero_area .hero_buttons > a {
    margin-bottom: 5px;
  }

  #fr .fr .details p {
    font-size: 12px;
  }

}



.reset-pass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 57vh;
}

.reset-pass > * {
  width: 100%;
  max-width: 400px;
  color: #737373;
}

.reset-pass > button {
  color: #f1f1ff;
}

.unsubscribe {
  height: 65vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.unsubscribe > div {
  width: 300px;
  padding: 10px;
  background-color: #faf4ff;
  box-shadow: 0px 2px 5px 1px rgba(160,65,223,0.1), 0px 0px 1px;
}
