#profile_listing {
    min-height: 65vh;
    position: relative;
}

.modal-content.fc {
    width: auto;
    background-color: #ded5ea;
}

.fc .modal_header h4 {
    border: none;
    font-size: 1.4rem;
    color: #7d7d7d;
    background-color: #ded5ea;
}

.fc .modal_body div {
    text-align: right;
}

.fc .modal_body div button {
    margin: 0px 6px;
}

.fc .modal_close {
    display: none;
}

#profile_listing .profile_listing_left .user_info_sum {
    padding: 20px 0px 10px 10px;
    width: 100%;
}

#profile_listing .profile_listing_left .user_info_sum .details p:first-of-type {
    font-size: 0.9rem;
}

#profile_listing .profile_listing_left .user_info_sum .details p {
    text-align: left !important;
}

#profile_listing .profile_listing_left .user_info_sum .details p:first-of-type > span:first-of-type {
    width: auto;
    max-width: 64%;
    display: inline-flex;
    overflow: hidden;
    white-space: nowrap;
}

#profile_listing .user_info_sum .details .chatnow.pink_color {
    /*width: 146px;*/
    text-align: center;
}

#profile_listing .user_info_sum .details .chatnow.add-to-list {
    margin-top: 0px;
}

#profile_listing .user_info_sum .details .chatnow.pink_color:hover {
    background-color: #f35ea0;
}

#profile_listing .profile_listing_right {
    height: auto;
    max-height: 280vh;
}
.profile_listing_right {
    background-color: #f8f8f8;
    padding: 6px;
    border-radius: 10px;
}

.profile_listing_left .info_area {
    height: 215px;
}

#profile_listing .profile_img {
    position: relative;
}

#profile_listing .profile_img .top_ticker {
    position: absolute;
    top: 11px;
    right: -2px;
    background-color: #fff;
    line-height: 1;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #ceb6f5;
    font-size: 9px;
}

.info_area .details-text {
    height: 110px;
    overflow: hidden;
}

.single_product.sidebar_product {
    cursor: pointer;
    margin: 0px 0px 6px 0px;
}

.single_product.sidebar_product:last-of-type {
    margin-bottom: 0px;
}

.single_product.sidebar_product > a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.single_product.sidebar_product > a > img {
    width: 95%;
}

.filter-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.filter-group > div {
    display: flex;
    flex-direction: row;
}

.filter-group > div > .filter-input {
    flex-grow: 1;
    max-width: 35%;
    margin-bottom: 3px;
}

.filter-group > div > .filter-input:last-of-type {
    margin-right: 0px !important;
}

.filter-group > div > .filter-input > label {
    font-size: 0.9rem;
    font-weight: 600;
}

.filter-group > div input, .filter-group > div select {
    font-size: 0.8rem;
    flex-grow: 1;
}

.filter-input select option {
    font-size: 0.8rem;
}

.paginate {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.paginate button {
    margin: 0 10px;
    width: 125px;
    z-index: 0;
}

.profile_listing_left .col-md-5 {
    padding: 0 0 0 12px;
}

.details {
    margin-top: 13px;
}

.details .badge {
    border-radius: 2px !important;
}

.rating_profile {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.rating_profile i {
    color: #F79028;
    margin-right: 4px;
}

.rating_profile .rate_no {
    line-height: 1;
    border: 1px solid #F79028;
    color: #F79028;
    display: inline-flex;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin-left: 10px;
}

.stage_0, .stage_1, .stage_2, .stage_3 {
    margin-left: 10px;
}

.stage_0 {
    color: #28a745;
}

.stage_1 {
    color: #f3f32b;
}

.stage_2 {
    color: #FFFF00;
}

.stage_3 {
    color: #FF0000;
}

.np_badge {
    font-size: 10px;
    transform: translateY(-3px);
    background: #964aff;
    margin-left: 10px;
    padding: 3px;
}

.modal_tnc .tnc_content .top_carret {
    top: auto;
    bottom: -35px;
}

.modal_tnc .tnc_content .top_carret i {
    color: #722ed1;
}

#profile_listing .header_filter .now_search {
    background-color: #722ed1;
    color: #fff;
    border: 0;
    padding: 1px;
    border-radius: 6px;
    margin-top: 4px;
    cursor: pointer;
    box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.4);
    font-size: 1.4rem;
    width: 100%;
}

.user_info_sum  .guarded {
    margin-left: 10px;
    text-shadow: 1px 1px 1px rgb(141,59,255);
    cursor: pointer;
}

.user_info_sum  .unguarded {
    color: #ab2d2e;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
    cursor: pointer;
    margin-left: 10px;
}

.chat-action {
    width: 196px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.chat-action a {
    margin: 0 1px !important;
    padding: 0 12px !important;
    box-shadow: 0px 0px 8px -1px rgba(0,0,0,0.4);
    min-width: 94px;
    text-align: center;
}

a.chatnow.disabled {
    background-color: #e1dfe4 !important;
}

.set-schedule > i {
    width: auto;
    height: auto;
    border: none;
}

.schedule-dates {
    list-style: decimal;
    margin: 0 0 5px 15px;
    font-size: 0.8rem;
    color: #bbbbbb;
}

#profile_listing .user_info_sum .details .chatnow.btn-danger {
    background-color: #ab2d2e;
}

#modal_confirm textarea {
    width: 100%;
    margin-top: 10px;
    resize: none;
    padding: 2px 8px;
    overflow-y: auto;
}

.extra-filter .ck-box {
    flex-direction: row;
    margin: 0 10px 10px;
}

.extra-filter .toggle-switch {
    margin-right: 10px;
}

.empty-list.share-list {
    transform: scale(0.9);
}

#profileTab li a {
    padding: 2px 10px !important;
}

.avail-tab ul {
    height: 150px;
    overflow-y: auto;
}

.avail-tab ul li {
    background-color: #e5dcf1;
    margin-bottom: 4px;
    padding: 2px 12px;
    border-radius: 16px;
    color: #5a5656;
    text-align: center;
    font-size: 0.9rem;
}

#profile_listing .info_area .now_tabs ul li a {
    font-size: 0.8rem;
    font-weight: bold;
}

@media screen and (max-width: 991px) {

    #profile_listing .header_filter .now_search {
        width: 100% !important;
    }
}

@media screen and (max-width: 767px) {

    .details {
        margin-top: 0px !important;
        padding-bottom: 12px;
    }

    .filter-input {
        max-width: 100%;
    }
    #profile_listing .header_filter .now_search {
        width: 100% !important;
    }

    #profile_listing .profile_listing_left .user_info_sum {
        padding: 20px 10px 10px 10px;
        border-bottom: 1px solid rgb(177,177,177);
        margin-bottom: 10px;
    }

    #profile_listing .profile_listing_left .user_info_sum .details p:first-of-type > span:first-of-type {
        width: auto;
        display: initial;
    }

    .avail-tab ul {
        height: auto;
    }

    .avail-tab ul li {
        background-color: #fff9fc;
    }

}

@media screen and (max-width: 575px) {

    .filter-group > div {
        flex-direction: column;
    }

    .filter-group > div > .filter-input {
        max-width: 100%;
        margin-right: 0px !important;
    }
}

@media screen and (max-width: 358px) {
    #profile_listing .profile_listing_left {
        height: auto;
        padding-bottom: 15px;
    }

    .info_area .now_tabs {
        flex-direction: column;
    }
    #profile_listing .info_area .now_tabs ul {
        display: inline-flex;
    }
    .info_area .now_tabs ul li {
        min-width: 100px;
        flex-grow: 1;
    }

}

@media screen and (max-width: 320px) {
    #profile_listing .info_area .now_tabs ul li:last-of-type a {
        border-color: #b5b5b5;
        border-radius: 0 0 3px 3px;
    }

}
