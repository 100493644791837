section#story-container {
    min-height: 75vh;
}

#story-container .profile_listing_right {
    top: 0px;
    height: 96vh;
    position: sticky;
}

.empty-stories {
    text-align: center;
    color: #a7a7a7;
    font-size: 1.1rem;
    position: relative;
    margin-top: 10%;
}

.story-create {
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 2px 10px -6px rgba(0,0,0,0.4);
}

.story-create  #story-text {
    width: 100%;
    resize: none;
    padding: 2px 8px;
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,0.2);
}

.story-create  #story-text:active, .story-create  #story-text:focus {
    outline: none;
}

.story-create .story-create-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.story-create-header .story-status {
    width: 75px;
    margin-right: 2px;
    font-size: 0.9rem;
    color: #9898b3;
    border: 1px solid;
    border-radius: 4px;
}

.story-create-header .story-status:active {
    outline: none;
}

.story-create-header .story-status .dropdown-item {
    cursor: pointer;
}

.story-create-header button i {
    margin-right: 10px;
}

.story-create-header h4 {
    color: #b6b5b7;
    font-size: 1rem;
}

.story-create #post-story {
    width: 100%;
}

.story-tabs > ul > li > .nav-link {
    font-weight: 600;
    padding: 4px 10px;
    color: #8d3bff;
}

.story-tabs > ul > li > .nav-link.active {
    color: #f273bc;
}

.story-author {
    padding: 4px;
    display: flex;
    margin-bottom: 4px;
    position: relative;
    flex-direction: row;
    background-color: #fef6ff;
    box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.2);
}

.story-author .story-action, .comment-author .comment-action {
    right: 6px;
    width: 15px;
    position: absolute;
}

.story-author .story-action i:first-of-type, .comment-author .comment-action i:first-of-type {
    color: #8d3bff;
}

.story-author .story-action i:last-of-type, .comment-author .comment-action i:last-of-type {
    color: #db1755;
}

.story-author .profile_info h2 > a {
    font-size: 1.2rem;
    color: #7c7c7d;
}

.story .shared-story > p > a {
    color: #989898;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: bold;
}

.comment-author {
    padding: 4px;
    display: flex;
    margin-bottom: 4px;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.comment {
    background-color: #ffffff;
}

.comment-body p {
    line-height: 1.5rem;
}

.comment-body p:first-of-type {
    color: #b9b9b9;
    line-height: 1.4;
    font-size: 0.6rem;
}

.comment-body p:last-of-type {
    color: #797979;
    font-size: 0.8rem;
    padding-right: 30px;
}

.story-author .profile_img, .comment-author .profile_img {
    margin-right: 10px;
}

.story-author .profile_img img, .comment-author .profile_img img {
    width: 50px;
}

.story-author .profile_info a:hover {
    color: rgba(141,59,255,0.72);
}

.story-author .profile_info p:last-of-type {
    color: #ababab;
    line-height: 1.5;
    font-size: 0.7rem;
}

.story {
    margin: 10px 0px;
    padding: 10px;
    box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.2);
}

.story .shared-story {
    margin-bottom: 10px;
}

.story .shared-story audio {
    width: 100%;
    padding: 4px 8px 4px 60px;
}

.story .shared-story > p {
    color: #989898;
    overflow-y: auto;
    max-height: 260px;
    white-space: pre-wrap;
    padding: 14px 8px 4px 60px;
    cursor: pointer;
    font-size: 0.9rem;
}

#modal-view-story .shared-story > p {
    cursor: default;
}

.story .story-footer {
    padding: 4px;
    display: flex;
    color: #848586;
    font-size: 0.8rem;
    align-items: center;
    flex-direction: row;
    background-color: #f8f5fd;
    justify-content: space-between;
}

.story .story-footer > div {
    display: flex;
    flex-direction: row;
}

.story .story-footer span {
    margin-right: 10px;
}

.story .story-footer span i {
    cursor: pointer;
    margin-left: 3px;
}

.story .story-footer span:first-of-type i {
    color: #1759db;
}

.story .story-footer span:last-of-type i {
    color: #db1755;
}

.story-footer p, .story-footer a {
    font-size: 0.8rem;
    color: #848586;
}

.story .comments {
    padding: 10px;
    margin: 10px 0px;
    background-color: #fdf8ff;
}

.comments .post-comment {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
}

.comments .post-comment input {
    width: 90%;
}

.comments .post-comment button {
    width: 100px;
}

.comments-container {
    max-height: 48vh;
    overflow-y: auto;
}

.no-comments {
    font-size: 1rem;
    text-align: center;
    color: #9a9a9a;
}

.comments-header {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.view-prev-comments {
    left: 45%;
    color: #959898;
    display: block;
    line-height: 1;
    padding: 2px 8px;
    font-size: 0.8rem;
    position: absolute;
    border-radius: 25px;
    box-shadow: 0px 2px 8px -3px rgba(0,0,0,0.3);
}

.comments-header p {
    color: #9a9a9a;
}

#remove_story .modal-body h4, #remove_comment .modal-body h4 {
    color: #9c9c9c;
    font-size: 1.4rem;
    margin: 0 0 20px 0;
}

#remove_story .modal-body > div > div button, #remove_comment .modal-body > div > div button {
    margin-right: 10px;
}

#edit_story #edit-story, #edit_comment #edit-comment {
    width: 100%;
    resize: none;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 8px;
    margin-bottom: 15px;
    height: 150px;
}

#edit_story #edit-story:focus, #edit_story #edit-story:active, #edit_comment #edit-comment:focus, #edit_comment #edit-comment:active {
    outline: none;
}


#edit_story .modal-body h4, #edit_comment .modal-body h4 {
    color: #9c9c9c;
    font-size: 1.4rem;
    margin: 0 0 20px 0;
}

#edit_story .modal-body > div > div button, #edit_comment .modal-body > div > div button {
    margin-right: 10px;
}

.friend_list_modal .modal-body > div {
    padding: 10px;
    max-height: 90vh;
    overflow-y: auto;
}

.friend_list_modal .modal-body > div::-webkit-scrollbar {
    display: none;
}

.friend_list_modal .modal-body h4 {
    color: #8f9292;
    font-size: 1.2rem;
    border-bottom: 1px solid;
}

.friend_list_modal .modal-body button {
    width: 100%;
    margin: 10px 0;
}

.story-notification {
    display: flex;
    flex-direction: row;
    margin: 10px;
    background-color: #fcf4ff;
    padding: 5px;
    align-items: center;
}

.story-notification .profile_img img {
    width: 50px;
    margin-right: 10px;
}

.story-notification .profile_info * {
    line-height: 1.2;
}

.story-notification .profile_info p {
    color: #757575;
}

.story-notification .profile_info p.small_font {
    font-size: 0.7rem;
    margin-bottom: 6px;
}
.story-notification .profile_info a {
    font-size: 0.9rem;
}

@media screen and (max-width: 767px){
    .story .shared-story > p {
        padding: 8px;
    }

    .story .shared-story audio {
        padding: 8px;
    }
}
