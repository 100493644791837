
.chat header#main_header {
    position: relative;
    width: 100%;
    z-index: 1;
    background: transparent linear-gradient(90deg, #812eed 0%, #ff7bb6 100%) 0% 0% no-repeat padding-box;
}

/*#chatbody {*/
/*    min-height: 79vh;*/
/*}*/

#chatbody {
    min-height: 78vh;
}

#chatbody .chat_sidebar_waper {
    border-radius: 22px 0px 0px 22px;
    padding: 0 0 0 10px;
    height: calc(100vh - 133px);
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior-y: contain;
    box-shadow: 4px 4px 10px -8px rgba(0,0,0,0.4);
    background: transparent linear-gradient(90deg, #e9d7ff7d 0%, #fbd0e326 100%) 0% 0% no-repeat padding-box;
}
#chatbody .chat_sidebar_waper::-webkit-scrollbar {
    display: none;
}
#chatbody .chat_sidebar_waper {
    scrollbar-width: none;
}
#chatbody .user_own {
    padding: 25px 25px 0 15px;
}
#chatbody .profile_img {
    margin-right: 15px;
    position: relative;
}
#chatbody .profile_img img {
    box-shadow: 0px 5px 10px #00000017;
    border: 1px solid #812EED;
    border-radius: 100%;
    width: 60px;
}
#chatbody .profile_img .top_ticker {
    position: absolute;
    top: -3px;
    right: 0;
    background-color: #fff;
    line-height: 1;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #ceb6f5;
    font-size: 9px;
}

.top_ticker.online {
    background-color: #0c7d02 !important;
    border: none !important;
}

#chatbody .search_waper {
    position: relative;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 0 25px 0 15px;
}
#chatbody .search_waper input {
    border-radius: 34px;
    border: 0;
    padding-right: 22px;
}
#chatbody .search_waper .fa-search {
    position: absolute;
    right: 30px;
    top: 10px;
    cursor: pointer;
    color: #8c8c8c;
}
#chatbody .search_waper ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 14px;
    color: #e2e2e2;
}
#chatbody .friends_profile {
    padding: 25px 25px 25px 15px;
    cursor: pointer;
    position: relative;
}
#chatbody .friends_profile.active {
    background: #fff;
    border-radius: 60px 0px 0px 60px;
}
#chatbody .chatnow {
    background: #722ed1;
    font-size: 10px;
    color: white;
    padding: 0px 10px;
    border-radius: 18px;
}
#chatbody .chat_body .chat_head {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
#chatbody .chat_body .chat_head .left a {
    color: #ddd;
    margin-right: 10px;
    font-size: 18px;
}
#chatbody .chat_body .chat_head .right h5 {
    margin: 0 15px 0 0;
}
#chatbody .chat_body .chat_messages {
    height: calc(100vh - 300px);
    overflow-y: auto;
    overscroll-behavior-y: contain;
    overflow-x: hidden;
    padding-top: 26px;
}
#chatbody .chat_body .chat_messages::-webkit-scrollbar {
    display: none;
}
#chatbody .chat_body .chat_messages {
    scrollbar-width: none;
}
#chatbody .chat_body .chat_messages .message .msg_waper {
    width: auto;
    min-width: 170px;
    max-width: 500px;
    background: none;
    border-radius: 35px;
    margin-bottom: 25px;
    display: inline-block;
}
#chatbody .chat_body .chat_messages .message p {
    padding: 10px 20px;
}
#chatbody .chat_body .chat_messages .message .sender_avt {
    position: absolute;
    bottom: 0;
    left: 0;
}
#chatbody .chat_body .chat_messages .message .sender_avt img {
    max-width: 15px;
    height: 15px;
    border: 1px solid #812eed;
    border-radius: 100%;
}
#chatbody .chat_body .chat_messages .message.self {
    text-align: right;
}
#chatbody .chat_body .chat_messages .message.self .sender_avt {
    left: initial;
    right: 0;
}
#chatbody .chat_body .chat_date {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #ddd;
}
#chatbody .chat_footer {
    background: #f8f8f8;
    padding: 25px;
    border-radius: 80px;
    position: relative;
}
#chatbody .chat_footer #send_msg {
    background: #fff 0% 0% no-repeat padding-box;
    border-radius: 34px;
    border: 0;
    padding: 9px 70px 0 14px;
    height: 40px;
    width: calc(100% - 50px);
}
#chatbody .chat_footer ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 14px;
    color: #e2e2e2;
}
#chatbody .chat_footer .gray_color {
    color: #adacac;
    font-size: 18px;
}
#chatbody .chat_footer .gray_color:hover {
    color: #585858;
}
#chatbody .chat_footer .smily,
#chatbody .chat_footer .call {
    margin-left: 10px;
}
#chatbody .chat_footer .smily i,
#chatbody .chat_footer .call i {
    color: #adacac;
    font-size: 18px;
}

#chatbody .chat_footer .smily i:hover,
#chatbody .chat_footer .call i:hover {
    color: #585858;
}

#chatbody .chat_footer .text-input-container {
    height: 36px;
}

#chatbody .chat_footer .send_action {

    position: absolute;
    right: -3px;
    top: -6px;
    width: 60px;
    height: 50px;
    background-color: transparent;
    border-radius: 0;
    font-size: 1.8rem;
    color: #812eed;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#chatbody .chat_footer .send_action i {
    background-color: #722ed1;
    color: #fff;
    font-size: 35px;
    transition: all 0.5s;
    cursor: pointer;
    position: relative;
    text-align: center;
    padding: 0;
    position: absolute;
    z-index: 555;
}

b {
    color: white;
}

/*#chatbody .chat_footer .send_action:hover {*/
/*    background-color: #6f35bb;*/
/*}*/

#chatbody .chat_footer .send_action i.fa-send:before, .fa-paper-plane:before {
    content: "\f1d8";
    position: relative;
    top: 0;
    left: 20px;
}

.avoid-clicks {
    pointer-events: none;
}

.send_action > div {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    background-color: transparent;
}

#chatbody .ads img {
    width: 100%;
    margin-bottom: 25px;
}

#chatbody .search_waper {
    margin-top: 25px;
    margin-bottom: 0;
    padding: 0 25px 20px 15px;
    border-bottom: 1px dashed #ddd;
    position: relative;
}

.friends_profile .guarded {
    margin-left: 10px;
    text-shadow: 1px 1px 1px rgb(141,59,255);
    cursor: pointer;
    float: right;
}

.friends_profile .unguarded {
    color: #ab2d2e;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
    cursor: pointer;
    margin-left: 10px;
    float: right;
}

#chatbody #text {
    position: absolute;
    width: calc(100% - 45px);
    height: 100%;
    background-color: #ffffff;
    color: #757473;
    border-radius: 50px;
    padding: 3px 14px;
    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.5);
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 0.9rem;
}

#chatbody #text::-webkit-scrollbar {
    display: none;
}

#chatbody .empty-chat {
    position: absolute;
    z-index: 2;
    left: 13px;
    top: 3px;
    font-size: 0.8rem;
    color: #c1c1c1;
    user-select: none;
    cursor: text;
    pointer-events: none;
}

#chatbody .chat_footer .smily {
    position: relative;
}

#chatbody .chat_footer .smily #smiley-list {
    display: none;
    position: absolute;
    flex-direction: row;
    flex-wrap: wrap;
    width: 200px;
    height: auto;
    background-color: #f9f9f9;
    padding: 6px;
    border-radius: 6px;
    bottom: 40px;
    left: -25px;
    box-shadow: 0px 2px 8px -5px rgba(0,0,0,0.5);
    overflow-y: auto;
    max-height: 280px;
}

#chatbody .chat_footer .smily #smiley-list::-webkit-scrollbar {
    display: none;
}

#chatbody .chat_footer .smily #smiley-list li {
    margin: 2px;
}

#chatbody .chat_footer .smily #smiley-list li:hover {
    box-shadow: 0px 2px 8px -5px rgba(0,0,0,0.5);
    cursor: pointer;
}

#call-window {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: #a467f3;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#call-window .call-details {
    width: 300px;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#call-window .call-action {
    width: 300px;
    height: 40vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#call-window .call-action i {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    box-shadow: 0px 0px 8px 0px rgba(255,255,255,0.4);
}

#call-window .call-action i:first-of-type {
    background-color: #0c7d02;
}

#call-window .call-action i:last-of-type {
    background-color: #ab2d2e;
}

@media screen and (max-width: 991px){

    #chatbody .chat_sidebar_waper {
        height: initial;
        background-color: #fff;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        background: none;
    }

    #chatbody .user_own {
        padding: 0px 25px 0 15px;
    }

    #chatbody .search_waper input {
        background-color: #f8f8f8;
    }


    #chatbody .friends_profile {
        border-bottom: 1px dashed #ddd;
    }

    .profile_listing_right {
        display: none;
    }

    #chatbody .pos_initial_mobile {
        position: initial !important;
    }
    #chatbody .chat_area_show_hide {
        position: fixed;
        top: 0;
        left: 0;
        background: white;
        padding: 4px;
        z-index: 999;
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        justify-content: space-between;
    }

    .chat_head .left > #search_chat_msg {
        height: 26px;
        top: 26px;
    }

    #chatbody .chat_body {
        height: 75vh;
    }

    #chatbody .chat_body .chat_messages {
        height: auto;
        max-height: calc(100vh - 225px);
    }

    #chatbody .chat_footer {
        position: relative;
        width: 100%;
        bottom: calc(0% + 54px);
        padding: 5px;
        border-radius: 7px;
    }

    #chatbody .chat_footer.ios {
        bottom: calc(0% + 70px);
    }

    #app_body #chatbody .chat_footer {
        bottom: calc(0% + 0px);
    }

    #chatbody .chat_body .chat_head {
        padding-bottom: 0;
        margin-bottom: 5px;
    }

    #chatbody .chat_footer #send_msg {
        padding: 8px 5px 0 14px;
    }

    #chatbody .chat_footer .send_action {
        /*right: -4px;*/
    }
    .chat_area_show_hide {
        display: none;
    }

    .ticontainer {
         top: -23px !important;
         left: 5px !important;
     }

}

.from .msg_waper.position-relative p {
    background-color: #ffd7ec !important;
    border-radius: 35px;
}
.self .msg_waper.position-relative p {
    background-color: #e2d7ec !important;
    border-radius: 35px;
}

#scroll_next {
    position: relative;
}

.empty-chat-list {
    margin-top: 100px;
    text-align: center;
}

.empty-chat-list h3 {
    color: #8c8c8c;
    font-size: 1.4rem;
}

.empty-chat-list .btn_outline {
    padding: 2px;
    width: 120px;
}

#main_footer.chat {
    padding-bottom: 10px;
    margin-top: 30px;
}

#app_body #main_footer.chat {
    margin-top: 17px;
}

#main_footer.chat:before {
    clip-path: none;
}

#main_footer.chat .newsletter_area {
    display: none;
}

#main_footer.chat .footer_menu {
    padding-top: 10px;
}

#main_footer.chat .footer_menu ul {
    display: none;
}

#main_footer.chat .copy {
    margin-top: 0px;
}

.chat .container-fluid {
    padding-left: 25px;
}

.empty-message-box {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: revert;
    justify-content: center;
    align-items: center;
}

.empty-message-box p {
    color: #8c8c9a;
    font-size: 1.4rem;
    text-align: center;
}

.message {
    position: relative;
    margin-bottom: 12px;
}

.message .msg-time {
    position: absolute;
    font-size: 0.8rem;
    color: #9a9a9a;
    top: -22px;
}

.message.from .msg-time {
    left: 20px;
}
.message.self .msg-time {
    right: 20px;
}

.remove-msg {
    position: absolute;
    color: #812eed;
    right: 0px;
    top: -40px;
    cursor: pointer;
    width: 18px;
}

.msg_waper:hover > .remove-msg {
    color: #e45151;
}

.remove-msg ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.remove-msg ul li {
    font-size: 2rem;
    height: 6px;
}

.remove-btn {
    display: none;
    position: absolute;
    z-index: 99;
    right: 2px;
    top: 0px;
    background-color: #fff5f5;
    padding: 2px 10px;
    border-radius: 6px;
    box-shadow: 0px 2px 8px -2px rgba(0,0,0,0.4);
    color: #e45151 !important;
    cursor: pointer;
}

.block-user .remove-msg {
    top: -23px;
}


.profile_listing_right {
    background-color: #f8f8f8;
    padding: 6px;
    border-radius: 10px;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    height: 85vh;
    scrollbar-width: none;
}

.profile_listing_right::-webkit-scrollbar {
    display: none;
}

.single_product.sidebar_product {
    cursor: pointer;
    margin: 0px 0px 6px 0px;
}

.single_product.sidebar_product:last-of-type {
    margin-bottom: 0px;
}

.single_product.sidebar_product > a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.single_product.sidebar_product > a > img {
    width: 100%;
}



/*Type indicator*/

.tiblock {
    align-items: center;
    display: flex;
    height: 17px;
}

.ticontainer {
    position: absolute;
    top: 0px;
    left: 95px;
    display: none;
    flex-direction: row;
    align-items: flex-end;
}

.ticontainer > span {
    font-size: 0.8rem;
    margin-right: 5px;
    font-weight: 500;
    color: #8a8a8a;
}

.ticontainer .tidot {
    background-color: #90949c;
}

.tidot {
    animation: typingAnimation 1.5s infinite ease-in-out;
    border-radius: 2px;
    display: inline-block;
    height: 4px;
    margin-right: 2px;
    width: 4px;
}

@keyframes typingAnimation{
    0%{
        transform:translateY(0px)
    }
    28%{
        transform:translateY(-5px)
    }
    44%{
        transform:translateY(0px)
    }
}

.tidot:nth-child(1){
    animation-delay:200ms;
}
.tidot:nth-child(2){
    animation-delay:300ms;
}
.tidot:nth-child(3){
    animation-delay:400ms;
}

.review__rating input {
    display: none;
}
.review__rating input:checked ~ label {
    color: #aaa;
}
.review__rating label {
    color: orange;
    font-size: 2rem;
}

#modal-rating .top_carret {
    top: auto;
    bottom: -35px;
}

#modal-rating .top_carret i {
    color: #722ed1;
}

.chat .top_colseable_area {
    margin-top: 10px;
}

.chat_body {
    position: relative;
}

.chat_body .right {
    position: relative;
}

.chat_body .block-user {
    position: absolute;
    right: -6px;
    top: 22px;
}

.chat_head .left {
    display: flex;
    flex-direction: row;
}

.chat_head .left > input {
    margin-right: 10px;
    height: 30px;
    position: absolute;
    width: 50%;
    top: 37px;
    border: none;
    display: none;
    outline: none !important;
}

.text-light-gray {
    color: #807f7f;
}

.notify {
    position: absolute;
    top: 35px;
    right: 30px;
    width: 20px;
}

.notify .heartbit {
    position: absolute;
    top: -20px;
    right: -16px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 4px solid #0c7d02;
    border-radius: 70px;
    animation: 1s ease-out infinite heartbit;
    -moz-animation: 1s ease-out infinite heartbit;
    -o-animation: 1s ease-out infinite heartbit;
    -webkit-animation: 1s ease-out infinite heartbit;
    -ms-animation: heartbit 1s ease-out;
}

.notify .point {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: #0c7d02;
    position: absolute;
    right: -7px;
    top: -10px;
}

@keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(.1);
        opacity: .1;
    }
    50% {
        -webkit-transform: scale(.5);
        opacity: .3;
    }
    75% {
        -webkit-transform: scale(.8);
        opacity: .5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

.chat .details {
    width: 100%;
}

.chat .action {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.chat .view-gallery {
    border-radius: 50%;
    height: 25px;
    width: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    box-shadow: 0px 0px 10px -3px #d461c9;
    background-color: #f7daea;
    color: #722ed1;
}

.chat .mfp-bg {
    z-index: 1060;
}

.chat .mfp-wrap {
    z-index: 1062;
}

.chatnow.not-friend {
    background: #ab9fa4 !important;
}

.accept-reject-req {
    width: 100%;
    padding: 10px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    box-shadow: 0px 2px 8px -5px rgba(0,0,0,0.4);
}
.accept-reject-req p {
    color: #e6609e;
    font-weight: 600;
}

.accept-reject-req > div {
    width: 224px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
