.single-post {
    min-height: 54vh;
}

.post-title {
    padding: 4px 10px;
    display: flex;
    margin-bottom: 4px;
    position: relative;
    flex-direction: column;
    background-color: #fef6ff;
    box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.2);
}

.post-title > h1 {
    font-size: 1.4rem;
    color: #656565;
    margin: 0;
}

.post-title > p {
    font-size: 0.7rem;
    color: #a5a5a5;
}

.post-body {
    margin: 30px 0;
    padding: 0 6px;
}

.post-body > h2 > *,
.post-body > h3 > * ,
.post-body > h4 > * ,
.post-body > h5 > * {
    color: #656565 !important;
    font-size: 1rem;
}

.post-body img {
    width: 100% !important;
}

.post-add-comment {
    padding: 4px 10px;
    display: flex;
    margin-bottom: 4px;
    position: relative;
    flex-direction: column;
    background-color: #fef6ff;
    box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.2);
}

.post-add-comment > textarea {
     padding: 10px;
     color: #797979;
 }

.post-add-comment button {
    margin-top: 10px;
}

.post-comments {
    padding: 10px;
    margin-bottom: 35px;
    position: relative;
}

.post-comment {
    background-color: #f9f5ff;
    padding: 8px;
    border-radius: 0 10px 0 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.post-comment p {
    font-size: 0.9rem;
    color: #7d7d7d;
}

.post-comment .profile_img img {
    width: 40px;
    margin-right: 10px;
}

.post-comment .comment-body > .comment-author {
    font-size: 1rem;
    line-height: 0;
    margin: 0;
    font-weight: 600;
    color: #888888;
}

.post-comment .comment-body > .comment-date {
    font-size: 0.7rem;
}

.load-comments {
    position: absolute;
    top: 30px;
    left: 40%;
    background-color: #fdebf3;
    padding: 2px 6px;
    border-radius: 6px;
    font-size: 0.8rem;
    line-height: 1.2;
    box-shadow: 0px 2px 3px 0px rgba(243,222,231,0.86);
    cursor: pointer;
    color: #797979;
}

.blog.story-footer span i {
    color: #722ed1 !important;
}

.post-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 6px;
}

.post-footer > div:first-child span:first-child {
    margin-right: 10px;
    cursor: pointer;
}

.remove-comment {
    position: absolute;
    right: 20px;
    cursor: pointer;
}

.profile_listing_right.blog-post {
    position: sticky;
    top: 0;
    height: fit-content;
    margin-bottom: 50px;
}
